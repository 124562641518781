import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ComponentCanDeactivate } from './can-deactivate-abstract';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '@app/core/auth';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { I18nService } from '@app/core';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  validate: boolean = true;
  currentComponent: ComponentCanDeactivate;
  nextState: RouterStateSnapshot;
  $isLoggedIn: Observable<boolean>;
  activeSession: boolean = true;
  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    private auth: AuthenticationService,
    private i18nService: I18nService
  ) {
    this.$isLoggedIn = this.auth.$isLoginSubject;
    this.$isLoggedIn.subscribe(val => {
      console.log('another subscription :' + val);
      this.activeSession = val;
    });
  }

  async canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    this.nextState = nextState;
    this.currentComponent = component;
    if (this.activeSession && component && !component.canDeactivate() && this.validate) {
      await this.discardConfirmMessage();
      this.validate = true;
    } else {
      return true;
    }
    return false;
  }

  async discardConfirmMessage() {
    const unsavedChangeHeaderLabel = this.i18nService.get('global.unsavedChangeHeaderLabel');
    const unsavedChangeTxt = this.i18nService.get('global.unsavedChangeBodyText');
    const cancelTxt = this.i18nService.get('global.unsavedCancel');
    const discardTxt = this.i18nService.get('global.unsavedDiscardChanges');

    const alert = await this.alertCtrl.create({
      header: unsavedChangeHeaderLabel,
      subHeader: unsavedChangeTxt,
      cssClass: 'ion-alert-listings',
      buttons: [
        {
          text: cancelTxt,
          role: 'Cancel',
          handler: () => {
            //console.log('Cancelar')
          }
        },
        {
          text: discardTxt,
          handler: () => {
            this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
            this.validate = false;
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      //this.router.navigateByUrl('/user-home/my-listings');
      this.validate = true;
    });
    await alert.present();
  }
}
