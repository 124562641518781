<div class="search-control">
  <app-tab-button
    [isActive]="
      searchModel.spotBuildingType === spotBuildingTypes.lease ||
      searchModel.spotBuildingType === spotBuildingTypes.coworking
    "
    [value]="spotBuildingTypes.lease"
    [label]="leaseLabel"
    (onClick)="setSpotBuildingType(spotBuildingTypes.lease)"
  >
  </app-tab-button>
  <app-tab-button
    [isActive]="searchModel.spotBuildingType === spotBuildingTypes.sale"
    [value]="spotBuildingTypes.sale"
    [label]="saleLabel"
    (onClick)="setSpotBuildingType(spotBuildingTypes.sale)"
  >
  </app-tab-button>
  <app-tab-button
    [isActive]="searchModel.spotBuildingType === spotBuildingTypes.company"
    [value]="spotBuildingTypes.company"
    [label]="companyLabel"
    (onClick)="setSpotBuildingType(spotBuildingTypes.company)"
  >
  </app-tab-button>

  <div class="search-fields">
    <div class="row no-gutters">
      <!--Dropdowns-->
      <div *ngIf="isNotCompanySearch()" class="col-12 col-md-3 pr-md-2">
        <div class="main-filter-item property-type">
          <div ngbDropdown>
            <button class="form-control block-select text-left" id="dropdownBasic1" ngbDropdownToggle>
              <span
                *ngIf="
                  searchModel.propertyTypeId == propertyTypes.industrial &&
                  searchModel.spotBuildingType !== spotBuildingTypes.coworking
                "
              >
                {{ 'global.industrial' | translate }}
              </span>
              <span
                *ngIf="
                  searchModel.propertyTypeId == propertyTypes.office &&
                  searchModel.spotBuildingType !== spotBuildingTypes.coworking
                "
              >
                {{ 'global.office' | translate }}
              </span>
              <span *ngIf="searchModel.spotBuildingType === spotBuildingTypes.coworking">
                {{ 'global.list-your-property.coworking' | translate }}
              </span>
              <span class="default-value" *ngIf="!searchModel.propertyTypeId">
                {{ 'global.menu.entities.buildingType' | translate }}
              </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-100">
              <button class="form-control" (click)="setPropertyTypeId(propertyTypes.industrial)" ngbDropdownItem>
                {{ 'global.industrial' | translate }}
              </button>
              <button class="form-control" (click)="setPropertyTypeId(propertyTypes.office)" ngbDropdownItem>
                {{ 'global.office' | translate }}
              </button>
              <button
                *ngIf="
                  searchModel.spotBuildingType === spotBuildingTypes.lease ||
                  searchModel.spotBuildingType === spotBuildingTypes.coworking
                "
                class="form-control"
                (click)="setPropertyTypeId(propertyTypes.office, spotBuildingTypes.coworking)"
                ngbDropdownItem
              >
                {{ 'global.list-your-property.coworking' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Keyword Search-->
      <div *ngIf="isNotCompanySearch()" class="col-10 col-md-6 mt-2 mt-md-0">
        <app-custom-keyword-search
          [propertyTypeId]="searchModel.propertyTypeId"
          [spotBuildingType]="searchModel.spotBuildingType"
          [selected]="searchModel.keyword"
          [propertyTypes]="propertyTypes"
          (onSelect)="selectKeywordItem($event)"
        ></app-custom-keyword-search>
      </div>

      <div *ngIf="isCompanySearch()" class="col-9 col-md-9 mt-2 mt-md-0">
        <div class="main-filter-item property-type autocompletecompanycontainer" *ngIf="false">
          <mat-form-field style="width: 100%; height: 36px; border-radius: 7px;">
            <input matInput [placeholder]="selectCompany" [formControl]="companyCtrl" [matAutocomplete]="auto" />
            <mat-autocomplete (optionSelected)="selectedState($event)" #auto="matAutocomplete">
              <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name">
                {{ company.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="main-filter-item property-type">
          <input
            matInput
            [placeholder]="selectCompany"
            [formControl]="companyCtrl"
            [matAutocomplete]="auto"
            style="width: 100%; height: 35px;"
          />
          <mat-autocomplete (optionSelected)="selectedState($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name">
              <span [innerHTML]="highlightOption(company.name)"></span>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="col-2 col-md-3 pl-2 mt-2 mt-md-0">
        <ion-button
          [disabled]="disableSearchBtn()"
          class="search-button main-filter-height search-filter main-filter-radius m-0 w-100"
          id="searchMainFilter"
          (click)="onSearch()"
        >
          <div class="search-icon d-flex d-md-block justify-content-center">
            <span class="d-none d-md-inline-block">{{ 'global.menu.search' | translate }}</span>
            <img class="mx-5 mx-md-0" src="assets/images/icons/search.png" alt="" />
          </div>
        </ion-button>
      </div>
    </div>
  </div>
</div>
