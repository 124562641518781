import * as tslib_1 from "tslib";
import { OnInit, ElementRef, ComponentRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatStepper, MatDialog } from '@angular/material';
import { IonReorderGroup, ToastController, AlertController } from '@ionic/angular';
import { RegisterListingService } from '@app/core/register-listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { ListingService, ListingFormService, EventCheckerService, ListingCleanupService, ListingDetailService } from '@app/core/listings';
import { ImageService, PurchaseCreditsService, I18nService } from '@app/core';
import { UtilityService } from '@app/core/helpers/utility-services';
var CreateListingComponent = /** @class */ (function () {
    function CreateListingComponent(breakpointObserver, registerListService, router, listingService, registerService, _ts, imageService, listingFormService, _eventChecker, toastCtrl, purchaseService, listingCleanupService, alertCtrl, i18nService, listingDetailsService, utilityService, dialog, route) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.registerListService = registerListService;
        this.router = router;
        this.listingService = listingService;
        this.registerService = registerService;
        this._ts = _ts;
        this.imageService = imageService;
        this.listingFormService = listingFormService;
        this._eventChecker = _eventChecker;
        this.toastCtrl = toastCtrl;
        this.purchaseService = purchaseService;
        this.listingCleanupService = listingCleanupService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.listingDetailsService = listingDetailsService;
        this.utilityService = utilityService;
        this.dialog = dialog;
        this.route = route;
        this.isMobile = false;
        this.previewLoaded = false;
        this.currentStep = 0;
        this.imgList = [];
        this.uploadingImages = false;
        this.tagValues = [];
        this.subTypes = [];
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.coworkingInfo = [];
        this.maximumPositions = [];
        this.videoURLInvalid = false;
        this.adminUpdate = false;
        this.skipDiscardChanges = false;
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.getParams();
        this.multiLangInit();
        this.getAmenities();
    }
    CreateListingComponent.prototype.ngOnDestroy = function () {
        console.log('Destroying component');
    };
    CreateListingComponent.prototype.ngOnInit = function () {
        this.skipDiscardChanges = false;
        this.detectFeatures();
    };
    CreateListingComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return (_this.currentStep = 0); }, 0);
    };
    CreateListingComponent.prototype.onStepperSelectionChange = function (evant) {
        this.scrollToSectionHook();
    };
    CreateListingComponent.prototype.scrollToSectionHook = function () {
        var element = document.querySelector('.stepperTop');
        if (element) {
            setTimeout(function () {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            }, 75);
        }
    };
    CreateListingComponent.prototype.goForward = function () {
        var _this = this;
        if (this.currentStep === 0) {
            this.listType = this.listingTypeForm.controls.commericalType.value;
        }
        setTimeout(function () {
            if (_this.currentStep === 5 && !_this.listing) {
                !_this.previewListing ? _this.setBuildingPreview() : _this.setBuildingPreview(true, _this.previewListing);
            }
            else if (_this.currentStep === 5 && _this.listing && _this.listing.listingStatus.id === 4 && _this.spotAdmin) {
                _this.displayAlert();
            }
            else if (_this.currentStep === 5 && _this.listing && _this.isUserAllowToEditPendingApprovalListing()) {
                _this.fromMyListings = false;
                _this.displayAlert();
            }
            else if (_this.currentStep === 5 && _this.listing) {
                _this.setBuildingPreview(false, null, true);
            }
            _this.stepper.next();
            _this.currentStep = _this.currentStep + 1;
        }, 0);
    };
    CreateListingComponent.prototype.goBack = function () {
        this.stepper.previous();
        this.currentStep = this.currentStep - 1;
        this.previewLoaded = false;
        this.listingTypeForm.markAsDirty();
    };
    CreateListingComponent.prototype.checkRole = function (role) {
        return this.listingCleanupService.checkRole(role);
    };
    CreateListingComponent.prototype.getParams = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingId, navigation, navigationState, credit;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        listingId = this.route.snapshot.paramMap.get('listingId');
                        navigation = this.router.getCurrentNavigation();
                        if (!(navigation == undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getListing(listingId).then(function (val) {
                                var state = { listing: _this.tempListing, myListingPage: true, date: new Date().getTime() };
                                navigationState = state;
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        navigationState = navigation.extras.state;
                        _a.label = 3;
                    case 3:
                        credit = navigationState && navigationState.credit ? navigationState.credit : null;
                        this.listingPurchaseId = navigationState && navigationState.credit ? credit.id : 0;
                        this.listingType = navigationState && navigationState.credit ? credit.premium : false;
                        this.listing = navigationState && navigationState.listing ? navigationState.listing : null;
                        this.listingClass = this.listing
                            ? this.listing.listingType
                            : navigationState && navigationState.credit && navigationState.credit.premium
                                ? 'PREMIUM'
                                : 'PROMOTED';
                        this.fromMyListings = navigationState && navigationState.myListingPage ? navigationState.myListingPage : null;
                        this.listingExpDate = navigationState && navigationState.credit ? credit.listingExpirationDate : null;
                        this.offeredByUserId = navigationState && navigationState.offeredByUserId ? navigationState.offeredByUserId : null;
                        this.company = navigationState && navigationState.company ? navigationState.company : null;
                        this.listing ? (this.offeredByUserId = this.listing.offeredByUser) : null;
                        this.listing && this.listing.offeredByUser ? (this.company = this.listing.offeredByUser.company) : null;
                        if (this.listingPurchaseId === 0 && !this.listing) {
                            this.router.navigateByUrl('/user-home');
                        }
                        this.listing ? this.initListing() : this.createInitForm();
                        this.initCoworkingFields();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.getListing = function (listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingDetail, listingPhotos;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingDetailsService.getListingDetails(listingId).toPromise()];
                    case 1:
                        listingDetail = _a.sent();
                        return [4 /*yield*/, this.listingDetailsService.getListingPhotos(listingId).toPromise()];
                    case 2:
                        listingPhotos = _a.sent();
                        this.tempListing = listingDetail.listing;
                        this.tempListing.listingPhotos = listingPhotos;
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.initCoworkingFields = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                response = this.listingCleanupService.initCoworkingFields();
                this.coworkingInfo = response.coworkingInfo;
                this.maximumPositions = response.maximumPositions;
                return [2 /*return*/];
            });
        });
    };
    CreateListingComponent.prototype.setCoworkingFields = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user;
            return tslib_1.__generator(this, function (_a) {
                user = localStorage.getItem('user');
                if (this.listingPriceForm.controls['coworkingEntries'].value) {
                    this.coworkingInfo = this.listingPriceForm.controls['coworkingEntries'].value;
                    if (this.coworkingInfo.length === 0) {
                        this.coworkingInfo = this.listingCleanupService.nullCoworkingFields();
                    }
                }
                if (this.listingPriceForm.controls['coworkingMaxPositions'].value) {
                    this.maximumPositions = [{ maximumPositions: this.listingPriceForm.controls['coworkingMaxPositions'].value }];
                }
                return [2 /*return*/];
            });
        });
    };
    CreateListingComponent.prototype.checkCowork = function () {
        return this.listingCleanupService.checkCowork(this.coworkingInfo);
    };
    CreateListingComponent.prototype.initListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getBuildingToRevise(this.listing.building.id)];
                    case 1:
                        _a.building = _b.sent();
                        return [4 /*yield*/, this.measurementS()];
                    case 2:
                        _b.sent();
                        this.listingClass = this.listing.listingType;
                        this.listingExpDate = this.listing.endDate;
                        this.reinitForms(this.listing, this.building);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.measurementS = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getMeasurementStands()];
                    case 1:
                        _a.measurementStandards = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getMeasurementTranslations(this.measurementStandards)];
                    case 2:
                        _b.measurementStandards = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.getMeasurementTranslations = function (measurements) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(measurements).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        measurements[key].measurement = this.getI18nValue(measurements[key].measurement);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, measurements];
            });
        });
    };
    CreateListingComponent.prototype.getMeasurementStands = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getMeasurementStandard().toPromise()];
            });
        });
    };
    CreateListingComponent.prototype.setBuildingPreview = function (val, previewListing, revise, admin, toApprove) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, mergedForm, tags, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
                            this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
                            this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
                        }
                        if (this.addressForm && this.addressForm.value && this.addressForm.value.address) {
                            obj = JSON.parse(this.addressForm.value.address);
                            if (obj['en']) {
                                obj['en'] = this.utilityService.removeHTMLCodeFrom(obj['en']);
                            }
                            if (obj['pt-br']) {
                                obj['pt-br'] = this.utilityService.removeHTMLCodeFrom(obj['pt-br']);
                            }
                            this.addressForm.value.address = JSON.stringify(obj);
                        }
                        mergedForm = tslib_1.__assign({}, this.listingTypeForm.value, this.detailsForm.value, this.addressForm.value, this.moreDetailsForm.value, this.listingPriceForm.value, this.listingFeaturesForm.value);
                        //this.company
                        mergedForm.vrTourUrl = this.vrTourUrl;
                        tags = { industrial: this.industrialFilters, office: this.officeFilters };
                        if (!!admin) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.registerListService.formBuildingListing(mergedForm, this.offeredByUserId, val ? true : null, this.listingPurchaseId, this.listingTypeForm.value.subType, this.listingType, tags, this.imgList, previewListing ? previewListing : null, revise ? this.listing : null)];
                    case 1:
                        _a.previewListing = _b.sent();
                        if (this.previewListing) {
                            this.imgList = this.previewListing.photos;
                            setTimeout(function () {
                                _this.previewLoaded = true;
                                _this.listingTypeForm.markAsPristine();
                                _this.detailsForm.markAsPristine();
                                _this.addressForm.markAsPristine();
                                _this.moreDetailsForm.markAsPristine();
                                _this.listingPriceForm.markAsPristine();
                                _this.listingFeaturesForm.markAsPristine();
                            }, 200);
                        }
                        else {
                            this.goBack();
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        try {
                            this.saveListing(val, previewListing, revise, admin, toApprove);
                        }
                        catch (error) {
                            this.showSaveErr();
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.submitListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.registerListService.updateToApproval(this.previewListing)];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            this.adminUpdate = true;
                            this.previewLoaded = false;
                            this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(res.id);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log('Error Submitting Listing: ', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.approveListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var action;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.purchaseService.approveListing(this.previewListing.id)];
                    case 1:
                        action = _a.sent();
                        if (action) {
                            this.adminUpdate = true;
                            this.previewLoaded = false;
                            this._eventChecker.updateListing$.next('no-refresh');
                            this.fromMyListings
                                ? this.router.navigateByUrl('/user-home/my-listings')
                                : this.navigateToDetailsPage(this.previewListing.id);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.navigateToDetailsPage = function (listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingDetailsService.getListingDetailsURLFrom(listingId)];
                    case 1:
                        detailsURL = _a.sent();
                        this.router.navigateByUrl(detailsURL);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.saveListing = function (val, previewListing, revise, admin, toApprove) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mergedForm, tags, tempListing, res, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.maximumPositions[0].maximumPositions && this.coworkingInfo) {
                            this.listingPriceForm.controls['coworkingMaxPositions'].setValue(this.maximumPositions[0].maximumPositions);
                            this.listingPriceForm.controls['coworkingEntries'].setValue(this.coworkingInfo);
                        }
                        mergedForm = tslib_1.__assign({}, this.listingTypeForm.value, this.detailsForm.value, this.addressForm.value, this.moreDetailsForm.value, this.listingPriceForm.value, this.listingFeaturesForm.value);
                        mergedForm.vrTourUrl = this.vrTourUrl;
                        tags = { industrial: this.industrialFilters, office: this.officeFilters };
                        return [4 /*yield*/, this.registerListService.formBuildingListing(mergedForm, this.offeredByUserId, val ? true : null, this.listingPurchaseId, this.listingTypeForm.value.subType, this.listingType, tags, this.imgList, previewListing ? previewListing : null, revise ? this.listing : null)];
                    case 1:
                        tempListing = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.registerListService.adminSaveListing(tempListing)];
                    case 3:
                        res = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        this.showSaveErr();
                        return [3 /*break*/, 5];
                    case 5:
                        if (!res) return [3 /*break*/, 8];
                        if (!toApprove) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.purchaseService.approveListing(this.listing.id)];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        res.listingPhotos = tempListing.photos;
                        this.finishActionBySpotAdmin(res.id);
                        return [3 /*break*/, 9];
                    case 8:
                        this.showSaveErr();
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.finishActionBySpotAdmin = function (listingId) {
        this.currentStep = 0;
        this.adminUpdate = true;
        this.skipDiscardChanges = true;
        this._eventChecker.updateListing$.next('no-refresh');
        this.fromMyListings ? this.router.navigateByUrl('/user-home/my-listings') : this.navigateToDetailsPage(listingId);
    };
    CreateListingComponent.prototype.getSpaceTranslations = function (subTypes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(subTypes).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        subTypes[key].name = this.getI18nValue(subTypes[key].name);
                        subTypes[key].selected = false;
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, subTypes];
            });
        });
    };
    CreateListingComponent.prototype.getSpaceTypes = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getBuildingSubTypes(id).toPromise()];
            });
        });
    };
    CreateListingComponent.prototype.getAmenities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.registerService.getAmenities('industrial')];
                    case 1:
                        _a.industrialFilters = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.registerService.getAmenities('office')];
                    case 2:
                        _b.officeFilters = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    CreateListingComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    CreateListingComponent.prototype.multiLangInit = function () {
        var response = this.listingCleanupService.multiLangInit();
        this.titleFieldOptions = response.titleFieldOptions;
        this.descriptionFieldOptions = response.descriptionFieldOptions;
    };
    CreateListingComponent.prototype.reinitForms = function (listing, building) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var forms, _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.listingFormService.reinitForm(listing, building, this.measurementStandards)];
                    case 1:
                        forms = _d.sent();
                        this.listingTypeForm = forms[0];
                        this.listingPriceForm = forms[4];
                        this.detailsForm = forms[1];
                        this.addressForm = forms[2];
                        this.moreDetailsForm = forms[3];
                        this.listingFeaturesForm = forms[5];
                        _a = this;
                        return [4 /*yield*/, this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value)];
                    case 2:
                        _a.subTypes = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.getSpaceTranslations(this.subTypes)];
                    case 3:
                        _b.subTypes = _d.sent();
                        if (this.listingTypeForm.controls['loadedSubTypes'].value) {
                            this.setCoworkingFields();
                            this.checkSubTypes();
                        }
                        if (!listing.listingPhotos) return [3 /*break*/, 5];
                        _c = this;
                        return [4 /*yield*/, this.listingFormService.checkListingPhotos(listing)];
                    case 4:
                        _c.imgList = _d.sent();
                        _d.label = 5;
                    case 5:
                        if (listing.vrTourUrl) {
                            this.vrTourUrl = listing.vrTourUrl;
                        }
                        this.listingTypeForm.markAsDirty();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.checkSubTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var existing;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                existing = this.listingTypeForm.controls['loadedSubTypes'].value;
                Object.keys(this.subTypes).forEach(function (key, idx) {
                    existing.findIndex(function (v) {
                        if (v.id === _this.subTypes[key].id) {
                            _this.subTypes[key].selected = true;
                        }
                    });
                });
                this.listingTypeForm.controls['subType'].setValue(this.subTypes);
                return [2 /*return*/];
            });
        });
    };
    CreateListingComponent.prototype.createInitForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var forms, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.measurementS()];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, this.listingFormService.createInitForm(this.measurementStandards)];
                    case 2:
                        forms = _c.sent();
                        this.listingTypeForm = forms[0];
                        this.detailsForm = forms[1];
                        this.addressForm = forms[2];
                        this.moreDetailsForm = forms[3];
                        this.listingPriceForm = forms[4];
                        this.listingFeaturesForm = forms[5];
                        _a = this;
                        return [4 /*yield*/, this.getSpaceTypes(this.listingTypeForm.controls['commericalType'].value)];
                    case 3:
                        _a.subTypes = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getSpaceTranslations(this.subTypes)];
                    case 4:
                        _b.subTypes = _c.sent();
                        this.listingTypeForm.controls['subType'].setValue(this.subTypes);
                        this.listingTypeForm.markAsDirty();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.canDeactivate = function () {
        if (this.listingPurchaseId === 0 && !this.listing) {
            return true;
        }
        if (this.adminUpdate || this.skipDiscardChanges) {
            return true;
        }
        else if (this.previewLoaded) {
            this.showUploadErr();
            return true;
        }
        return (this.listingTypeForm &&
            !this.listingTypeForm.dirty &&
            this.detailsForm &&
            !this.detailsForm.dirty &&
            this.addressForm &&
            !this.addressForm.dirty &&
            this.moreDetailsForm &&
            !this.moreDetailsForm.dirty &&
            this.listingPriceForm &&
            !this.listingPriceForm.dirty &&
            this.listingFeaturesForm &&
            !this.listingFeaturesForm.dirty);
    };
    CreateListingComponent.prototype.showUploadErr = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: 'Your listing has been saved as a draft.',
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CreateListingComponent.prototype.showSaveErr = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: 'There was an issue saving your listing.',
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CreateListingComponent.prototype.displayAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this._ts.instant('global.updateListing'),
                            subHeader: this._ts.instant('global.updateListingText'),
                            cssClass: 'ion-alert-listings',
                            backdropDismiss: true,
                            buttons: [
                                {
                                    text: this._ts.instant('Cancel'),
                                    role: 'Cancel',
                                    handler: function () {
                                        _this.goBack();
                                    }
                                },
                                {
                                    text: this._ts.instant('Ok'),
                                    handler: function () {
                                        _this.setBuildingPreview(false, null, true, true, false);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateListingComponent.prototype.onVrTourChanged = function (urlValidation) {
        this.videoURLInvalid = urlValidation.videoURLInvalid;
        this.vrTourUrl = urlValidation.vrTourSelected;
    };
    CreateListingComponent.prototype.detectFeatures = function () {
        var _this = this;
        this._eventChecker.updateOfficeFilters$.subscribe(function (pOfficeFilters) {
            if (pOfficeFilters) {
                _this.officeFilters = pOfficeFilters;
            }
        });
        this._eventChecker.updateIndustrialFilters$.subscribe(function (pFilters) {
            if (pFilters) {
                _this.industrialFilters = pFilters;
            }
        });
    };
    CreateListingComponent.prototype.isUserAllowToEditPendingApprovalListing = function () {
        return this.registerListService.isUserAllowToEditPendingApprovalListing(this.listing);
    };
    CreateListingComponent.prototype.cancelAndNavigateToDetails = function () {
        this.fromMyListings = false;
        this.finishActionBySpotAdmin(this.listing.id);
    };
    return CreateListingComponent;
}());
export { CreateListingComponent };
