import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { I18nService } from '../i18n.service';
import { BehaviorSubject } from 'rxjs';
var ListingDetailService = /** @class */ (function () {
    function ListingDetailService(http, i18NService) {
        this.http = http;
        this.i18NService = i18NService;
        this.host = 'api/listings';
        this.listingPhotosEndpoint = 'api/listingPhotosByListing/image-formats-only';
        this.listingIdsEndpoint = "api/listings/displayable/fastIds";
        this.publicProfilePhoto = 'api/account/photoByUserLogin';
        this.listingPropertySubtype = 'api/lookUpTables/listings/:id/property-subtypes';
        this.favoritesCountSubject = new BehaviorSubject(0);
        this.getUserInfo = 'api/listings/userinfo';
        this.listingDetails = 'api/listings/:id/details';
        this.buildingNames = 'api/buildings/buildingNames';
        this.favoriteListing = 'api/favorites/';
        this.subscribe = 'api/mailing-subscription/';
        this.revisionChangesAPI = 'revision-changes';
    }
    ListingDetailService.prototype.getListingById = function (listingId) {
        return this.http.get(this.host + "/" + listingId, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getListingPhotos = function (listingId) {
        return this.http
            .get(this.listingPhotosEndpoint + "?listingId=" + listingId, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getListingDetails = function (id) {
        return this.http.get(this.host + "/" + id + "/detail", { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getListingDetailsSummarized = function (baseId, id, lang) {
        return this.http
            .get(this.host + "/" + baseId + "/" + id + "/detail/" + lang + "/summarized", { observe: 'response' })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.checkListingDetailRoute = function (id, lang) {
        return this.http
            .get('api/listings/' + id + '/detail/url-data/' + lang, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.mostRecentActiveListingFromBaseId = function (baseId, lang) {
        return this.http
            .get('api/listings/' + baseId + '/detail/most-recent/active/' + lang, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getListingPropertySubtype = function (id) {
        return this.http
            .get("api/lookUpTables/listings/" + id + "/property-subtypes", { observe: 'response' })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getProfilePicture = function (login) {
        return this.http.get(this.publicProfilePhoto + "/" + login, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getAllListingsIds = function () {
        return this.http.get(this.listingIdsEndpoint).toPromise();
    };
    ListingDetailService.prototype.getBuildingNames = function () {
        return this.http.get("" + this.buildingNames, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getUserById = function (id, listingId) {
        return this.http
            .get(listingId ? this.getUserInfo + "/" + id + "?listingId=" + listingId : this.getUserInfo + "/" + id, {
            observe: 'response'
        })
            .pipe(map(function (val) { return val.body; }));
    };
    ListingDetailService.prototype.getFavoriteListings = function () {
        return this.http.get(this.favoriteListing);
    };
    ListingDetailService.prototype.updateFavoritesCount = function () {
        var _this = this;
        this.getFavoriteListings().subscribe(function (val) {
            _this.favoritesCountSubject.next(val.filter(function (l) { return l.listingStatus.id == 4; }).length);
        });
    };
    ListingDetailService.prototype.toggleFavoriteListing = function (listing, isFavorite) {
        var _this = this;
        var currentFavoritesCount = this.favoritesCountSubject.getValue();
        if (isFavorite) {
            return this.http.delete("" + this.favoriteListing + listing.id + "/unmark").pipe(tap(function () {
                if (_this.isActiveListing(listing)) {
                    _this.favoritesCountSubject.next(currentFavoritesCount - 1);
                }
            }));
        }
        else {
            return this.http.put("" + this.favoriteListing + listing.id + "/mark", {}).pipe(tap(function () {
                if (_this.isActiveListing(listing)) {
                    _this.favoritesCountSubject.next(currentFavoritesCount + 1);
                }
            }));
        }
    };
    ListingDetailService.prototype.isActiveListing = function (listing) {
        return listing && listing.listingStatus.id == 4;
    };
    ListingDetailService.prototype.getListingDetailsURLFrom = function (listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lang, res, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.i18NService.getCurrentLanguage();
                        return [4 /*yield*/, this.checkListingDetailRoute(listingId, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        detailsURL = '/detail/' + listingId;
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18NService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18NService.addCurrentLanguageToPath(detailsURL);
                            }
                        }
                        return [2 /*return*/, detailsURL];
                }
            });
        });
    };
    ListingDetailService.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.log('roles undefined --> ', e);
        }
    };
    ListingDetailService.prototype.getRevisionOrActiveVersionBasedOnUserRole = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isSpotAdmin;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data.listingStatus.id == 4)) return [3 /*break*/, 2];
                        if (!(data.nextListingId && data.nextListingStatus)) return [3 /*break*/, 2];
                        if (!(data.nextListingStatus.id == 8)) return [3 /*break*/, 2];
                        isSpotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
                        if (!!isSpotAdmin) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getListingById(data.nextListingId).toPromise()];
                    case 1:
                        data = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, data];
                }
            });
        });
    };
    ListingDetailService.prototype.subscription = function (mail) {
        return this.http.post("" + this.subscribe, mail).pipe(map(function (val) { return val; }));
    };
    ListingDetailService.prototype.revisionChanges = function (activeListingId, revisionListing, lang) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var revisionChangesMap;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.host + "/" + activeListingId + "/" + this.revisionChangesAPI + "/" + lang, revisionListing)
                            .toPromise()];
                    case 1:
                        revisionChangesMap = _a.sent();
                        return [2 /*return*/, revisionChangesMap];
                }
            });
        });
    };
    return ListingDetailService;
}());
export { ListingDetailService };
