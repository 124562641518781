import * as tslib_1 from "tslib";
import { OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper, MatSnackBar } from '@angular/material';
import { ListingService, EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
var AddressComponent = /** @class */ (function () {
    function AddressComponent(listingService, eventCheckerSrv, i18NService, snackbar, zone) {
        this.listingService = listingService;
        this.eventCheckerSrv = eventCheckerSrv;
        this.i18NService = i18NService;
        this.snackbar = snackbar;
        this.zone = zone;
    }
    AddressComponent.prototype.ngOnDestroy = function () {
        //this.eventCheckerSrv.updateBuildingLocation$.unsubscribe();
    };
    AddressComponent.prototype.ngOnInit = function () {
        this.closeTxt = this.i18NService.get('Close');
        this.duplicateLocationTxt = this.i18NService.get('map.duplicateLocation');
        if (!this.actualProperty) {
            this.getStates();
            this.multiLangInit();
            this.clearInputs();
            this.addressForm.get('city').disable();
            this.detectBuildingLocationChanges();
        }
        else {
            this.clearInputs();
            this.getStates();
            this.buildingInfo = this.getBuildingAddress(this.actualProperty.id);
        }
    };
    AddressComponent.prototype.clearInputs = function () {
        this.addressForm.controls['address'].setValue('');
        this.addressForm.controls['state'].setValue(null);
        this.addressForm.controls['city'].setValue(null);
        this.addressForm.controls['zip'].setValue('');
        this.addressForm.controls['buildingLocation'].setValue('');
        this.addressForm.get('state').enable();
        this.addressForm.get('address').enable();
        this.addressForm.get('city').enable();
        this.addressForm.get('zip').enable();
    };
    AddressComponent.prototype.setReadOnly = function (building) {
        if (building) {
            this.buildingName = this.getI18nValue(building.address);
            this.addressForm.controls['address'].setValue(this.buildingName);
            this.addressForm.controls['state'].setValue(building.region.countryState.id);
            this.getCities(building.region.countryState.id);
            this.addressForm.get('state').disable();
            this.addressForm.get('address').disable();
            this.addressForm.get('city').disable();
            this.addressForm.get('zip').disable();
        }
        if (building.neighborhood) {
            this.addressForm.controls.neighborhood.setValue(building.neighborhood);
        }
    };
    AddressComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18NService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    AddressComponent.prototype.getBuildingAddress = function (buildingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getBuilding(buildingId)];
                    case 1:
                        _a.buildingInfo = _b.sent();
                        this.setReadOnly(this.buildingInfo);
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressComponent.prototype.getStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getStates()];
                    case 1:
                        _a.states = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressComponent.prototype.getCities = function (stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.listingService.getAllCitiesByState(stateId ? stateId : this.addressForm.value.state)];
                    case 1:
                        _a.cities = _b.sent();
                        if (stateId) {
                            this.addressForm.controls.city.setValue(this.buildingInfo.city.id);
                            this.addressForm.controls.zip.setValue(this.buildingInfo.postalCode);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressComponent.prototype.getLoadedCity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    AddressComponent.prototype.setCity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.addressForm.controls['state'] && this.addressForm.controls['state'].value) {
                    this.addressForm.get('city').enable();
                    return [2 /*return*/, this.getCities()];
                }
                else {
                    this.addressForm.get('city').disable();
                }
                return [2 /*return*/];
            });
        });
    };
    AddressComponent.prototype.multiLangInit = function () {
        this.addressOptions = {
            columnLabel: 'global.list-your-property.address',
            element: { input: true },
            formArrayName: 'address',
            required: true
        };
    };
    AddressComponent.prototype.addressChange = function (value) {
        var _this = this;
        if (value.address.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.address).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.address[key];
                    if (obj['en'] === '' || obj['pt-br'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1
                ? this.addressForm.controls['address'].setValue(null)
                : this.addressForm.controls['address'].setValue(JSON.stringify(mergedObject_1));
        }
        else {
            this.addressForm.controls['address'].setValue(null);
        }
    };
    AddressComponent.prototype.detectBuildingLocationChanges = function () {
        var _this = this;
        this.eventCheckerSrv.updateBuildingLocation$.subscribe(function (buildingLocation) {
            if (buildingLocation && buildingLocation.stateName) {
                var differentLocation = !_this.addressForm.controls['buildingLocation'] ||
                    _this.addressForm.controls['buildingLocation'].value != buildingLocation;
                if (_this.states && differentLocation) {
                    _this.addressForm.controls['buildingLocation'].setValue(buildingLocation);
                    var statesFound = _this.states.filter(function (state) { return JSON.parse(state.name).en === buildingLocation.stateName; });
                    if (statesFound && statesFound.length > 0) {
                        var currentStateId = _this.addressForm.controls['state'].value;
                        if (currentStateId === statesFound[0].id) {
                            _this.setCityFromName(buildingLocation);
                        }
                        else {
                            _this.addressForm.controls['state'].setValue(statesFound[0].id);
                            _this.setCityFromName(buildingLocation);
                        }
                    }
                }
            }
        });
    };
    AddressComponent.prototype.setCityFromName = function (buildingLocation) {
        var _this = this;
        if (buildingLocation && buildingLocation.cityName) {
            if (!this.cities || this.cities[0].countryState.id !== this.addressForm.controls['state'].value) {
                this.setCity().then(function () {
                    _this.setCityFromLocationAndCheckForDuplicateBuildings(buildingLocation);
                });
            }
            else {
                this.setCityFromLocationAndCheckForDuplicateBuildings(buildingLocation);
            }
        }
        else {
            this.addressForm.controls['city'].setValue('');
        }
    };
    AddressComponent.prototype.setCityFromLocationAndCheckForDuplicateBuildings = function (buildingLocation) {
        var cityName = buildingLocation.cityName;
        var citiesFound = this.cities.filter(function (city) { return JSON.parse(city.name).en === cityName || JSON.parse(city.name)['pt-br'] === cityName; });
        if (citiesFound && citiesFound.length > 0) {
            var cityId = citiesFound[0].id;
            this.addressForm.controls['city'].setValue(cityId);
            buildingLocation.cityId = cityId;
            this.checkDuplicateBuildingLocation(buildingLocation);
            console.log('Finishing City From name');
        }
    };
    AddressComponent.prototype.checkDuplicateBuildingLocation = function (buildingLocation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.listingService.checkDuplicateBuildingLocation(buildingLocation).then(function (result) {
                    if (result && result.body) {
                        var nearbyLocations = result.body;
                        if (nearbyLocations.length > 0) {
                            _this.zone.run(function () {
                                _this.snackbar.open(_this.duplicateLocationTxt, _this.closeTxt, {
                                    duration: 2000,
                                    verticalPosition: 'bottom',
                                    horizontalPosition: 'center',
                                    panelClass: ['snack-background-spot']
                                });
                                console.log('Showing SnackBar');
                            });
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return AddressComponent;
}());
export { AddressComponent };
