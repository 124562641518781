import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { AccountService } from '@app/core/auth';
import { TurnOffNotification } from '@app/models/turn-off-notifications.model';
import { NotificationService } from '../services/notification.service';
var SettingsNotificationComponent = /** @class */ (function () {
    function SettingsNotificationComponent(breakpointObserver, notificationService, accountService) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.notificationService = notificationService;
        this.accountService = accountService;
        this.isMobile = false;
        this.turnOff = new TurnOffNotification();
        this.recommendation = true;
        this.favorites = true;
        this.spotSet = true;
        this.userRestrictions = {
            favorites: { excludeEmail: false, excludeSpotApp: false, notificationActivityType: null },
            recommendation: { excludeEmail: true, excludeSpotApp: false, notificationActivityType: null },
            spotSet: { excludeEmail: false, excludeSpotApp: false, notificationActivityType: null }
        };
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SettingsNotificationComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var account, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.accountService.getAccount().toPromise()];
                    case 1:
                        account = _b.sent();
                        this.favorites = account.favoriteNotification;
                        this.recommendation = account.recommendationNotification;
                        this.spotSet = account.spotSetNotification;
                        _a = this;
                        return [4 /*yield*/, this.notificationService.getNotificationRestrictions()];
                    case 2:
                        _a.userRestrictions = _b.sent();
                        console.log('User Resctrictions DB', this.userRestrictions);
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsNotificationComponent.prototype.turnOffNotification = function (notificationType) {
        this.turnOff.recommendationNotification = this.recommendation;
        this.turnOff.favoriteNotification = this.favorites;
        this.turnOff.spotSetNotification = this.spotSet;
        this.notificationService.turnOffNotification(this.turnOff).then(function (val) { });
        this.validateTooglesOnRestrictions(notificationType);
    };
    SettingsNotificationComponent.prototype.saveUserRestrictions = function (checkboxname, isChecked) {
        if (checkboxname == 'emailRecomCheck') {
            this.userRestrictions.recommendation.excludeEmail = !isChecked;
        }
        if (checkboxname == 'appRecomCheck') {
            this.userRestrictions.recommendation.excludeSpotApp = !isChecked;
        }
        if (checkboxname == 'emailSpotSetCheck') {
            this.userRestrictions.spotSet.excludeEmail = !isChecked;
        }
        if (checkboxname == 'appspotSetCheck') {
            this.userRestrictions.spotSet.excludeSpotApp = !isChecked;
        }
        if (checkboxname == 'emailFavCheck') {
            this.userRestrictions.favorites.excludeEmail = !isChecked;
        }
        if (checkboxname == 'appFavCheck') {
            this.userRestrictions.favorites.excludeSpotApp = !isChecked;
        }
        this.notificationService.saveNotificationRestrictions(this.userRestrictions);
    };
    SettingsNotificationComponent.prototype.validateTooglesOnRestrictions = function (notificationType) {
        var userRestrictionsCopy = tslib_1.__assign({}, this.userRestrictions);
        if (notificationType == 'FAVORITES') {
            userRestrictionsCopy.favorites.excludeEmail = this.favorites;
            userRestrictionsCopy.favorites.excludeSpotApp = this.favorites;
            this.userRestrictions.favorites.excludeEmail = !this.favorites;
            this.userRestrictions.favorites.excludeSpotApp = !this.favorites;
        }
        if (notificationType == 'RECOMMENDATION') {
            userRestrictionsCopy.recommendation.excludeSpotApp = this.recommendation;
            userRestrictionsCopy.recommendation.excludeEmail = this.recommendation;
            this.userRestrictions.recommendation.excludeEmail = !this.recommendation;
            this.userRestrictions.recommendation.excludeSpotApp = !this.recommendation;
        }
        if (notificationType == 'SPOTSET') {
            userRestrictionsCopy.spotSet.excludeSpotApp = this.spotSet;
            userRestrictionsCopy.spotSet.excludeEmail = this.spotSet;
            this.userRestrictions.spotSet.excludeSpotApp = !this.spotSet;
            this.userRestrictions.spotSet.excludeEmail = !this.spotSet;
        }
        this.notificationService.saveNotificationRestrictions(userRestrictionsCopy);
    };
    return SettingsNotificationComponent;
}());
export { SettingsNotificationComponent };
