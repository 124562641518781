import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import {} from 'googlemaps';
import { add } from 'lodash';
import { I18nService } from '@app/core';

export class Marker {
  constructor(public lat: number, public lng: number) {}
}

export class BuildingLocation {
  constructor(
    public marker: Marker,
    public address: string,
    public stateName: string,
    public cityName: string,
    public cityId?: number
  ) {}
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private i18NService: I18nService) {}
  enterAddressDesc: string;
  title: string;
  buildingMarker: Marker;
  defaultLat = -23.5955;
  defaultLng = -46.6885;
  zoom: number;
  address: string;
  private geoCoder: any;
  @Output() newLocationEvent = new EventEmitter<BuildingLocation>();

  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  ngOnInit() {
    this.zoom = 15;
    this.enterAddressDesc = this.i18NService.get('map.enterAddress');
    this.title = this.i18NService.get('map.whereBuildingLocated');
    this.buildingMarker = new Marker(this.defaultLat, this.defaultLng);
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      this.setNewMarker(this.defaultLat, this.defaultLng);

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude
          this.setNewMarker(place.geometry.location.lat(), place.geometry.location.lng());
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    /*
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.buildingMarker.lat = position.coords.latitude;
        this.buildingMarker.lng = position.coords.longitude;
        this.getAddress(this.buildingMarker.lat, this.buildingMarker.lng);
      });
    }
    */
  }

  addMarker(event: any) {
    const coords: { lat: number; lng: number } = event.coords;
    this.setNewMarker(coords.lat, coords.lng);
  }

  markerDragEnd($event: MouseEvent) {
    this.setNewMarker($event.coords.lat, $event.coords.lng);
  }

  async findAddressStateAndCityFrom(marker: Marker) {
    this.geoCoder.geocode({ location: { lat: marker.lat, lng: marker.lng } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          let address: string = results[0].formatted_address;
          if (address) {
            for (let i = 0; i < 2; i++) {
              let lastIdx = address.lastIndexOf(',');
              address = address.substring(0, lastIdx);
            }
          }
          let stateName = null;
          let cityName = null;
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {
              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == 'administrative_area_level_1') {
                stateName = results[0].address_components[i].long_name;
              } else if (
                results[0].address_components[i].types[b] == 'locality' ||
                results[0].address_components[i].types[b] == 'administrative_area_level_2'
              ) {
                cityName = results[0].address_components[i].long_name;
              }
            }
          }
          const buildingLocation = new BuildingLocation(marker, address, stateName, cityName);
          this.newLocationEvent.emit(buildingLocation);
        } else {
          console.log('No results found');
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  }

  private setNewMarker(lat: number, lng: number) {
    if (lat && lng) {
      this.buildingMarker.lat = lat;
      this.buildingMarker.lng = lng;
      this.findAddressStateAndCityFrom(this.buildingMarker);
    }
  }
}
