import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { Observable } from 'rxjs';
import { TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageService } from '@app/core/image.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  registerForm: FormGroup;
  registerFormDetails: FormGroup;
  passwordLength: boolean = false;
  passwordMatch: boolean = false;
  isCompleted: boolean = false;
  uniqueEmailError: boolean = false;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  lastStep: boolean = false;
  companySelected: any;
  filteredCompanies: Observable<any[]>;
  selectedCompany: any = [];
  allCompanies: any = [];
  stepIndex: number = 0;
  emailName: any;
  isLoaded: boolean = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  originalImg: any;
  fileName: any;
  ios: boolean = false;
  android: boolean = false;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
    private imgService: ImageService,
    private deviceDetectorService: DeviceDetectorService,
    private loadingController: LoadingController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.detectDevice();
  }

  portChange(event: { component: IonicSelectableComponent; value: any }) {
    this.selectedCompany = event.value;
  }

  detectDevice() {
    if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
      this.ios = true;
    } else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
      this.android = true;
    }
  }

  async showUploadError() {
    this.registerService.showUploadErr();
  }

  async changePassword() {
    let changePasswordObj: any = {};
    changePasswordObj.newPassword = this.registerForm.controls.emailGroup.value.password;
    changePasswordObj.secretKey = this.registerService.getKeyForChangePassword();
    this.registerService
      .changePassword(changePasswordObj)
      .then((response: any) => {
        this.showPasswordChangeMessage();
      })
      .catch(err => {
        console.log('Password change failed');
      });
  }

  trimValue(formControl: any) {
    this.registerForm.get('emailGroup.email').setValue(formControl.trim());
  }

  async showPasswordChangeMessage() {
    const alert = await this.alertCtrl.create({
      header: 'New Password',
      subHeader: 'Password changed. Please log in using your new password.',
      buttons: ['OK']
    });
    alert.onDidDismiss().then(() => {
      this.router.navigateByUrl('/');
    });
    await alert.present();
  }

  createForm() {
    this.registerForm = this.fb.group(
      {
        emailGroup: this.fb.group({
          password: ['', Validators.compose([Validators.required])],
          confirmPass: ['', Validators.compose([Validators.required])]
        })
      },
      {
        validator: this.checkFormErrors
      }
    );
  }

  checkFormErrors(formGroup: any) {
    let password = formGroup.controls.emailGroup.controls['password'].value;
    let confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
    if (password.length < 6) {
      return password.length < 6 ? { passwordLength: true } : null;
    } else if (password !== confirmPass) {
      return password !== confirmPass ? { passwordMatch: true } : null;
    }

    if (formGroup.controls.emailGroup.status == 'INVALID') {
      return { passwordLength: true };
    }
  }
}
