import { OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//TODO: Expand to detect browser/mobile and determine to use cordova geolocation or capacitor plugins
var GeoLocationService = /** @class */ (function () {
    function GeoLocationService() {
        this.isTracking = false;
        this.positions = new BehaviorSubject(null);
    }
    GeoLocationService.prototype.ngOnInit = function () { };
    GeoLocationService.prototype.startBrowserTracking = function () {
        var _this = this;
        if (navigator && navigator.geolocation) {
            this.isTracking = true;
            try {
                navigator.geolocation.watchPosition(function (position) {
                    _this.positions.next(position);
                });
            }
            catch (err) {
                console.log(err);
            }
        }
    };
    Object.defineProperty(GeoLocationService.prototype, "geolocationRecord", {
        get: function () {
            return this.positions.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return GeoLocationService;
}());
export { GeoLocationService };
