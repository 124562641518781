import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { SpotCardSliderService } from '../services/spot-card-slider.service';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
var AppSpotCardSlider = /** @class */ (function () {
    function AppSpotCardSlider(spotCardSliderSvc, breakpointObserver) {
        var _this = this;
        this.spotCardSliderSvc = spotCardSliderSvc;
        this.breakpointObserver = breakpointObserver;
        this.noDataMsg = 'No Data Found';
        this.cardContainerClass = '';
        this.spotBuildingSearchPayload = cloneDeep(this.spotCardSliderSvc.defaultSearchRequest);
        this.favoriteChangeEvent = new EventEmitter();
        this.totalCountEvent = new EventEmitter();
        this.firstFetch = true;
        this.queryPage = 1;
        this.disabledNext = false;
        this.disabledPrev = true;
        this.allSpotIds = [];
        this.spotBuildings = [];
        this.loading = false;
        this.activeListingIds = [];
        this.changeSlide$ = new Subject();
        this.slideOptionOverride = tslib_1.__assign({}, this.spotCardSliderSvc.defaultSliderOpts, { breakpoints: this.breakpoints || this.spotCardSliderSvc.defaultSliderOpts.breakpoints });
        this.promotedCards = [1, 2, 3];
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    AppSpotCardSlider.prototype.ngOnInit = function () {
        if (!this.isPromoted) {
            this.fetchSpotBuildings();
        }
    };
    AppSpotCardSlider.prototype.compareObjects = function (previous, current) {
        return JSON.stringify(previous) === JSON.stringify(current);
    };
    AppSpotCardSlider.prototype.ngOnChanges = function (changes) {
        var payload = changes.spotBuildingSearchPayload;
        var goToRefresh = false;
        if (payload) {
            var previousPayload = payload.previousValue;
            var currentValue = payload.currentValue;
            if (previousPayload) {
                if (!this.compareObjects(previousPayload, currentValue)) {
                    goToRefresh = true;
                }
            }
            if (!this.compareObjects(currentValue, this.spotBuildingSearchPayload)) {
                goToRefresh = true;
            }
        }
        console.log('Changes Slider: ', changes, this.spotBuildingSearchPayload, goToRefresh);
        if (goToRefresh) {
            this.refresh();
        }
    };
    AppSpotCardSlider.prototype.refresh = function () {
        this.clear();
        if (!this.isPromoted) {
            this.fetchSpotBuildings();
        }
    };
    AppSpotCardSlider.prototype.clear = function () {
        this.allSpotIds = [];
        this.spotBuildings = [];
    };
    AppSpotCardSlider.prototype.fetchSpotBuildings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.loading = true;
                    this.spotCardSliderSvc
                        .searchAllSpotIdsByCompany(this.spotBuildingSearchPayload)
                        .then(function (results) {
                        var spotBuildingIds = results.body;
                        if (spotBuildingIds.length % 2 == 0) {
                            _this.querySize = Math.round(spotBuildingIds.length / 2);
                            _this.querySize = !_this.isMobile && _this.querySize > 2 ? _this.querySize : 3;
                        }
                        else {
                            _this.querySize = Math.ceil(spotBuildingIds.length / 3);
                        }
                        if (spotBuildingIds) {
                            _this.spotCardSliderSvc
                                .searchSpotsByCompanyAsync(spotBuildingIds, _this.queryPage, _this.querySize)
                                .then(function (result) {
                                var spotBuildings = result;
                                _this.firstFetch = false;
                                _this.allSpotIds = spotBuildingIds;
                                _this.updateCounts();
                                _this.spotBuildings = _this.allSpotIds.map(function () { return null; });
                                _this.concatSpotBuildings(spotBuildings);
                                _this.loading = false;
                            });
                        }
                    });
                }
                catch (error) {
                    this.loading = false;
                    this.firstFetch = false;
                    this.handleError(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AppSpotCardSlider.prototype.updateCounts = function () {
        this.totalCountEvent.emit(this.allSpotIds ? this.allSpotIds.length : 0);
    };
    AppSpotCardSlider.prototype.fetchMore = function (_a) {
        var pageSize = _a.pageSize, pageNumber = _a.pageNumber;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var spotBuildings, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.loading = true;
                        this.queryPage = pageNumber;
                        return [4 /*yield*/, this.spotCardSliderSvc.getSpotBuildingsPaginated(this.allSpotIds, pageNumber, pageSize)];
                    case 1:
                        spotBuildings = _b.sent();
                        this.concatSpotBuildings(spotBuildings);
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        this.loading = false;
                        this.handleError(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppSpotCardSlider.prototype.onSlideChange = function (direction) {
        this.changeSlide$.next(direction);
    };
    AppSpotCardSlider.prototype.concatSpotBuildings = function (spotBuildings) {
        if (spotBuildings && spotBuildings.length) {
            var copy = cloneDeep(this.spotBuildings);
            var loadedIndex = (this.queryPage - 1) * this.querySize;
            copy.splice.apply(copy, tslib_1.__spread([loadedIndex, spotBuildings.length], spotBuildings));
            this.spotBuildings = copy;
        }
    };
    AppSpotCardSlider.prototype.handleError = function (error) {
        this.loading = false;
        this.error = error;
    };
    AppSpotCardSlider.prototype.onSliderUpdate = function (_a) {
        var isEnd = _a.isEnd, isBeginning = _a.isBeginning;
        this.disabledNext = !!isEnd;
        this.disabledPrev = !!isBeginning;
    };
    AppSpotCardSlider.prototype.favoriteChange = function (changedFavorite) {
        if (this.onlyFavorites) {
            this.keepFavoriteSpots(changedFavorite);
            this.favoriteChangeEvent.emit(changedFavorite);
            this.updateCounts();
        }
    };
    AppSpotCardSlider.prototype.keepFavoriteSpots = function (updatedFavorite) {
        this.spotBuildings = this.spotBuildings.filter(function (spot) { return spot && spot.favorite; });
        if (updatedFavorite && updatedFavorite.id) {
            this.allSpotIds = this.allSpotIds.filter(function (spotId) { return spotId != updatedFavorite.id; });
        }
    };
    return AppSpotCardSlider;
}());
export { AppSpotCardSlider };
