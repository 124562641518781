import { environment } from '@env/environment';
import { GoogleAnalyticsType } from './google-analytics-type';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as i0 from "@angular/core";
import * as i1 from "ngx-google-analytics";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(gaService) {
        this.gaService = gaService;
    }
    AnalyticsService.prototype.event = function (eventName, params) {
        gtag('event', eventName, params);
    };
    AnalyticsService.prototype.sentEvent = function (analyticsType, currentDevice) {
        var trackingId = null;
        var eventNameForGA = null;
        switch (analyticsType) {
            case GoogleAnalyticsType.CLICK_ON_EMAIL:
                eventNameForGA = 'Entrar em contato email';
                trackingId = environment.agentEmailTrackingID;
                break;
            case GoogleAnalyticsType.CLICK_ON_PHONE:
                eventNameForGA = 'Entrar em contato telefone';
                trackingId = environment.agentPhoneTrackingID;
                break;
            case GoogleAnalyticsType.CLICK_ON_WHATSAPP:
                eventNameForGA = 'Ver mais whatsapp';
                trackingId = environment.agentWhatsAppTrackingID;
                break;
            case GoogleAnalyticsType.CLICK_ON_GET_STARTED:
                eventNameForGA = 'Cadastre-se';
                trackingId = environment.getStartedUserRegistrationID;
                break;
            case GoogleAnalyticsType.CLICK_ON_INSTALL_APP:
                eventNameForGA = 'install';
                trackingId = environment.installAppTrackingID;
                break;
            case GoogleAnalyticsType.EMAIL_SENT:
                eventNameForGA = 'Enviar email';
                trackingId = environment.sendEmailTrackingID;
                break;
            default:
                console.error(' No tracking ');
                trackingId = null;
        }
        if (trackingId) {
            console.log('Sending GA to: ', trackingId, analyticsType);
            this.gaService.gtag('event', 'conversion', {
                send_to: trackingId,
                event_callback: this.callback()
            });
            this.gaService.event("" + eventNameForGA, 'Contact Events', currentDevice);
        }
    };
    AnalyticsService.prototype.callback = function () { };
    AnalyticsService.prototype.init = function () {
        if (environment.baseTrackingID && environment.baseTrackingID.length > 0) {
            this.configTrackingID(environment.baseTrackingID);
        }
        this.configTrackingID(environment.agentEmailTrackingID);
        this.configTrackingID(environment.agentPhoneTrackingID);
        this.configTrackingID(environment.agentWhatsAppTrackingID);
        this.configTrackingID(environment.getStartedUserRegistrationID);
    };
    AnalyticsService.prototype.addUserIdToGA = function () {
        //(window as any).gtag('set', { 'user_id': userId });
        //this.gaService.event('User', 'Set User ID', userId);
        if (environment.analyticsTracking && environment.analyticsTracking.length > 0) {
            this.configTrackingID(environment.analyticsTracking);
        }
    };
    AnalyticsService.prototype.configTrackingID = function (trackingId) {
        try {
            var script1 = document.createElement('script');
            script1.async = true;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
            document.head.appendChild(script1);
            var script2 = document.createElement('script');
            var user = JSON.parse(localStorage.getItem('user'));
            if (!user || user == undefined) {
                script2.innerHTML =
                    "\n        window.dataLayer = window.dataLayer || [];\n        function gtag(){dataLayer.push(arguments);}\n        gtag('js', new Date());\n        gtag('config', '" +
                        trackingId +
                        "', {'send_page_view': true});\n      ";
            }
            else {
                script2.innerHTML =
                    "\n        window.dataLayer = window.dataLayer || [];\n        function gtag(){dataLayer.push(arguments);}\n        gtag('js', new Date());\n        gtag('set', {'user_id': '" +
                        user.id +
                        "'});\n        gtag('config', '" +
                        trackingId +
                        "', {'send_page_view': true, 'user_id': '" +
                        user.id +
                        "'});\n        gtag('config', '" +
                        trackingId +
                        "', {'user_id': '" +
                        user.id +
                        "'});\n      ";
                this.gaService.gtag('set', 'user_id', '' + user.id + '');
            }
            document.head.appendChild(script2);
            console.log(trackingId + " configuration: OK");
        }
        catch (ex) {
            console.error("Error appending google analytics: " + trackingId);
            console.error(ex);
        }
    };
    AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.GoogleAnalyticsService)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };
