import { Injectable, OnDestroy, Inject, setTestabilityGetter, OnInit } from '@angular/core';
import { Observable, SubscriptionLike, Subject, Observer, interval, of } from 'rxjs';
import { filter, map, tap, delay, mapTo, take } from 'rxjs/operators';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

import { share, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { IWebsocketService, IWsMessage, WebSocketConfig } from './websocket.interfaces';
import { config } from './websocket.config';
import { AccountService } from '@app/core/auth/account.service';
import { guiid } from '@app/websockets/socket-hash.helper';
import { Router } from '@angular/router';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { isString } from 'lodash';
import { HttpClient, HttpService } from '@app/core';

export enum SiilaBusAuthActions {
  HANDSHAKE = 'handshake', //CLIENT ID SENT TO MESSAGE BUS TO CREATE GROUP OR SEE IF MATCHES EXISTING GROUP
  KICK = 'kick', //TERMINATE CLIENT ID GROUP
  AUTHORIZE = 'authorize',
  DISCOVER = 'discover',
  DEPOSIT = 'deposit'
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements IWebsocketService, OnDestroy {
  private config: WebSocketSubjectConfig<IWsMessage<any>>;

  private websocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;

  private reconnection$: Observable<number>;
  private websocket$: WebSocketSubject<IWsMessage<any>>;
  private connection$: Observer<boolean>;
  private targetSource$: Subject<any>;
  private wsMessages$: Subject<IWsMessage<any>>;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  private isConnected: boolean;

  public status: Observable<boolean>;

  constructor(
    @Inject(config) private wsConfig: WebSocketConfig,
    private router: Router,
    private account: AccountService,
    private http: HttpService
  ) {
    this.wsMessages$ = new Subject<IWsMessage<any>>();
    this.targetSource$ = new Subject<any>();

    this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
    this.reconnectAttempts = wsConfig.reconnectAttempts || 10; // number of connection attempts

    this.config = {
      url: wsConfig.url,
      closeObserver: {
        next: (event: CloseEvent) => {
          this.websocket$ = null;
          this.connection$.next(false);
        }
      },
      openObserver: {
        next: (event: Event) => {
          this.connection$.next(true);
          this.handshake();
        }
      }
    };

    // connection status
    this.status = new Observable<boolean>(observer => {
      this.connection$ = observer;
    }).pipe(
      share(),
      distinctUntilChanged()
    );

    // run reconnect if not connection
    this.statusSub = this.status.subscribe(isConnected => {
      this.isConnected = isConnected;

      if (!this.reconnection$ && typeof isConnected === 'boolean' && !isConnected) {
        this.reconnect();
      }
    });

    this.websocketSub = this.wsMessages$.subscribe(null, (error: ErrorEvent) =>
      console.error('WebSocket error!', error)
    );

    this.wsMessages$.subscribe((message: any) => {
      console.log('received', message);
      if (isString(message)) {
        try {
          message = JSON.parse(message);
        } catch (e) {}
      }
      if (message.Items && message.Items.length) {
        const authorizer = message.Items.find((i: any) => {
          return i.authorizer_token;
        });
        if (authorizer) {
          if (authorizer && authorizer.authorizer_token) {
            try {
              const authValue = isString(authorizer.authorizer_token)
                ? JSON.parse(authorizer.authorizer_token)
                : authorizer.authorizer_token;
              if (authValue.tokenValue && authValue.user) {
                if (!localStorage.getItem('user') && !localStorage.getItem('token')) {
                  localStorage.setItem('token', JSON.stringify(authValue.tokenValue));
                  localStorage.setItem('ls.token', JSON.stringify(authValue.tokenValue));
                  localStorage.setItem('user', JSON.stringify(authValue.user));
                  this.account.currentUser$.next(authValue.user);
                  window.location.reload();
                }
              }
            } catch (e) {
              console.warn('discovery failed parsing #->', e);
            }
          }
        }
      }
      if (message.Attributes) {
        localStorage.setItem('client_guiid', JSON.stringify(message.Attributes['client_guiid']));
        this.discoveryBroker();
      } else {
        const messageContent = message;
        if (
          messageContent.action === SiilaBusAuthActions.AUTHORIZE &&
          messageContent.data &&
          messageContent.data.tokenValue &&
          messageContent.data.user
        ) {
          // if (!localStorage.getItem('token') && !localStorage.getItem('user')) {
          //all check if tokens are still valid
          localStorage.setItem('token', JSON.stringify(messageContent.data.tokenValue));
          localStorage.setItem('user', JSON.stringify(messageContent.data.user));
          //right here
          this.account.currentUser$.next(messageContent.data.user);
          window.location.reload();
          // }
        } else if (message.action === SiilaBusAuthActions.KICK) {
          if (localStorage.getItem('token') || localStorage.getItem('user')) {
            // localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.account.currentUser$.next(null);
            this.router.navigate(['']);
          }
          // window.location.reload();
        }
      }
    });

    //this prevents the socket connetion from ddos over iframe
    // if (window.location === window.parent.location)
    // this.connect();
  }

  ngOnDestroy() {
    this.websocketSub.unsubscribe();
    this.statusSub.unsubscribe();
  }

  /*
   * connect to WebSocked
   * */
  public connect(): void {
    if (window.location === window.parent.location) {
      this.websocket$ = new WebSocketSubject(this.config);

      this.websocket$.subscribe(
        message => this.wsMessages$.next(message),
        (error: Event) => {
          if (!this.websocket$) {
            // run reconnect if errors
            this.reconnect();
          }
        }
      );
    }
  }

  /*
   * reconnect if not connecting or errors
   * */
  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$)
    );

    this.reconnection$.subscribe(() => this.connect(), null, () => {
      // Subject complete if reconnect attemts ending
      this.reconnection$ = null;

      if (!this.websocket$) {
        this.wsMessages$.complete();
        this.connection$.complete();
      }
    });
  }

  /*
   * on message event
   * */
  public on<T>(event: string): Observable<T> {
    if (event) {
      return this.wsMessages$.pipe(
        filter((message: IWsMessage<T>) => message.event === event),
        map((message: IWsMessage<T>) => message.data)
      );
    }
  }

  /*
   * on message to server
   * */
  public send(action: string, data: any = {}): void {
    if (event && this.isConnected) {
      this.websocket$.next(<any>{ action, data });
    } else {
      console.error('Send error!');
    }
  }

  public kick() {
    this.send(SiilaBusAuthActions.KICK, JSON.stringify({}));
  }

  /*
   * after connect exchange guiid
   * */
  private handshake() {
    return this.send(SiilaBusAuthActions.HANDSHAKE, guiid());
  }

  /*
   * discovers clients in bus and exchanges token with provider id
   * */
  private discover() {
    return this.send(SiilaBusAuthActions.DISCOVER, 'client_discovery');
  }

  private async discoveryBroker() {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      // try {
      const sessionIsValid = await this.isSessionDestroyed();
      sessionIsValid.username ? this.deposit() : this.clearCredentialsFromSocket();
    } else {
      this.discover();
    }
  }

  public clearCredentialsFromSocket() {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      // localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.kick();
      window.location.reload();
    }
  }

  public isSessionDestroyed(): Promise<any> {
    return this.http.get('api/authenticate').toPromise();
  }

  private deposit() {
    this.send(SiilaBusAuthActions.DEPOSIT, {
      tokenValue: JSON.parse(localStorage.getItem('token')),
      user: JSON.parse(localStorage.getItem('user'))
    });
  }
}
