import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { I18nService } from '../i18n.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ListingDetailService {
  private host: string = 'api/listings';
  private listingPhotosEndpoint: string = 'api/listingPhotosByListing/image-formats-only';
  private listingIdsEndpoint: string = `api/listings/displayable/fastIds`;
  private publicProfilePhoto: string = 'api/account/photoByUserLogin';
  private listingPropertySubtype: string = 'api/lookUpTables/listings/:id/property-subtypes';
  public favoritesCountSubject = new BehaviorSubject<number>(0);
  public getUserInfo: string = 'api/listings/userinfo';
  public listingDetails: string = 'api/listings/:id/details';
  public buildingNames: string = 'api/buildings/buildingNames';
  public favoriteListing: string = 'api/favorites/';
  public subscribe: string = 'api/mailing-subscription/';
  revisionChangesAPI: any = 'revision-changes';

  constructor(private http: HttpClient, private i18NService: I18nService) {}

  getListingById(listingId: number) {
    return this.http.get(`${this.host}/${listingId}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getListingPhotos(listingId: number) {
    return this.http
      .get(`${this.listingPhotosEndpoint}?listingId=${listingId}`, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  getListingDetails(id: number) {
    return this.http.get(`${this.host}/${id}/detail`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getListingDetailsSummarized(baseId: number, id: number, lang: string) {
    return this.http
      .get(`${this.host}/${baseId}/${id}/detail/${lang}/summarized`, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  checkListingDetailRoute(id: any, lang?: string) {
    return this.http
      .get('api/listings/' + id + '/detail/url-data/' + lang, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  mostRecentActiveListingFromBaseId(baseId: any, lang: string) {
    return this.http
      .get('api/listings/' + baseId + '/detail/most-recent/active/' + lang, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  getListingPropertySubtype(id: number) {
    return this.http
      .get(`api/lookUpTables/listings/${id}/property-subtypes`, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  getProfilePicture(login: any) {
    return this.http.get(`${this.publicProfilePhoto}/${login}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getAllListingsIds() {
    return this.http.get(this.listingIdsEndpoint).toPromise();
  }

  getBuildingNames() {
    return this.http.get(`${this.buildingNames}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getUserById(id: any, listingId: any) {
    return this.http
      .get(listingId ? `${this.getUserInfo}/${id}?listingId=${listingId}` : `${this.getUserInfo}/${id}`, {
        observe: 'response'
      })
      .pipe(map(val => val.body));
  }

  getFavoriteListings() {
    return this.http.get(this.favoriteListing);
  }

  updateFavoritesCount() {
    this.getFavoriteListings().subscribe((val: Array<any>) => {
      this.favoritesCountSubject.next(val.filter(l => l.listingStatus.id == 4).length);
    });
  }

  toggleFavoriteListing(listing: any, isFavorite: boolean) {
    let currentFavoritesCount = this.favoritesCountSubject.getValue();
    if (isFavorite) {
      return this.http.delete(`${this.favoriteListing}${listing.id}/unmark`).pipe(
        tap(() => {
          if (this.isActiveListing(listing)) {
            this.favoritesCountSubject.next(currentFavoritesCount - 1);
          }
        })
      );
    } else {
      return this.http.put(`${this.favoriteListing}${listing.id}/mark`, {}).pipe(
        tap(() => {
          if (this.isActiveListing(listing)) {
            this.favoritesCountSubject.next(currentFavoritesCount + 1);
          }
        })
      );
    }
  }

  isActiveListing(listing: any) {
    return listing && listing.listingStatus.id == 4;
  }

  async getListingDetailsURLFrom(listingId: any) {
    let lang = this.i18NService.getCurrentLanguage();
    let res: any = await this.checkListingDetailRoute(listingId, lang).toPromise();
    let detailsURL = '/detail/' + listingId;
    if (res) {
      detailsURL = res.detailsURL;
      if (this.i18NService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18NService.addCurrentLanguageToPath(detailsURL);
      }
    }
    return detailsURL;
  }

  checkRole(role: string) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles ? user.roles.includes(role) : false;
    } catch (e) {
      console.log('roles undefined --> ', e);
    }
  }

  async getRevisionOrActiveVersionBasedOnUserRole(data: any) {
    if (data.listingStatus.id == 4) {
      if (data.nextListingId && data.nextListingStatus) {
        if (data.nextListingStatus.id == 8) {
          const isSpotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
          if (!isSpotAdmin) {
            data = await this.getListingById(data.nextListingId).toPromise();
          }
        }
      }
    }
    return data;
  }

  subscription(mail: any) {
    return this.http.post(`${this.subscribe}`, mail).pipe(map(val => val));
  }

  async revisionChanges(activeListingId: number, revisionListing: any, lang: string) {
    const revisionChangesMap = await this.http
      .post(`${this.host}/${activeListingId}/${this.revisionChangesAPI}/${lang}`, revisionListing)
      .toPromise();
    return revisionChangesMap;
  }
}
