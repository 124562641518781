import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListingModalService } from '../../services/listing-modal.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { ImageService } from '@app/core/image.service';
import { Router } from '@angular/router';
import { ListingService, ListPropertyService } from '@app/core/listings';
import { ListingCarouselService } from '@app/core/listings';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { AgentNotificationComponent } from '../agent-notification/agent-notification.component';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { MatDialog } from '@angular/material';
import { MyListingMenuComponent } from '@app/shared/my-listing-menu/my-listing-menu.component';
import { LoginFormDialogService } from '@app/shared/login/login-form-dialog.service';
import { take } from 'rxjs/operators';
import { BuildingRentRollsService } from '@app/search-navigation/services/building-rent-rolls.service';
import { EventCheckerService } from '@app/core/listings';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { Subscription } from 'rxjs';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { element } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-listing-detail-page',
  templateUrl: './listing-modal.component.html',
  styleUrls: ['./listing-modal.component.scss']
})
export class ListingModalComponent implements OnInit, OnDestroy {
  @Input() listing: any;
  @Input() previewListing?: boolean;
  @Output() scrollToView: EventEmitter<any> = new EventEmitter();

  @ViewChild('listingMapView', { static: false }) listingMapView: any;
  @Output('removedTagsFromNearBy') removedTagsFromNearBy: EventEmitter<any> = new EventEmitter();

  formData: any;
  angForm: FormGroup;
  submitted = false;
  contactEmail: boolean = false;
  contactPhone: boolean = false;
  sendMessage: boolean = false;
  brokerEmail: any = [];
  brokerPhone: any = [];
  advertisements: any = [];
  options = {
    singleMarker: true,
    ignoreZoom: false,
    zoom: 15
  };
  marker: any;
  oneRequired: any;
  brokerImage: any = '';
  listingsInSameProperty: any;
  listingIdsInSameProperty: any;
  totalCountListingsInSameProperty: number;
  showMoreIconListingsInSameProperty: boolean;
  loading: boolean;
  currentPage: number;
  listingTags: any = [];
  typesTags: any = [];
  whatsappPhone: boolean = false;
  coworkingType: any;
  coworkingPositions: number;
  coworkingDailyPrice: number;
  coworkingMonthlyPrice: number;
  faMapMarkerAlt: any;
  isLoggedIn$: any;
  dialogRef: any;
  availableSpaces: any[] = [];
  availableSpaceCols: string[] = [];
  screenWidth: any;
  screenHeight: any;
  nearbySearchCriteria: any;
  nearbyListings: any[] = [];
  selectedPropertyListings: any[] = [];
  user: any;
  listingAdmin: boolean;
  spotAdmin: boolean;
  selectedBuildingName: string;
  searchTags: any;
  listingUpdateSubscription: Subscription;
  loginSubscription: Subscription;
  isLoggedIn: boolean;
  pricePrivateOption: boolean = false;
  priceSharedOption: boolean = false;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  revisionListings: any;
  seeRevisionChanges: boolean = false;

  constructor(
    private _eventChecker: EventCheckerService,
    private fb: FormBuilder,
    private router: Router,
    private listingModalService: ListingModalService,
    private listingDetailService: ListingDetailService,
    private imgService: ImageService,
    private listingService: ListingService,
    private listingPropertyService: ListPropertyService,
    private listingCarouselService: ListingCarouselService,
    private userActivityService: UserActivityService,
    private i18nService: I18nService,
    private whatsAppService: WhatsAppService,
    private agentNotPopOverCtrl: PopoverController,
    private auth: AuthenticationService,
    private loginFormDialog: LoginFormDialogService,
    private dialog: MatDialog,
    private buildingRentRollService: BuildingRentRollsService,
    private nearbyListingSvc: NearbyListingSvc,
    private analyticsService: AnalyticsService,
    private listingSearchService: ListingSearchService,
    private deviceDetector: DeviceDetectorService
  ) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
    this.loginSubscription = this.auth.$isLoginSubject.subscribe((val: boolean) => {
      this.isLoggedIn = val;
      this.getNearestListings(history.state);
      this.getListingsInSameProperty();
    });
    this.setListing();
    this.createForm();
    // Avoid calling details API twice
    if (this.previewListing && this.listing) {
      this.getListingDetails();
    }
    this.getListingTags(this.listing);
    this.getTypesTags(this.listing);
    this.getAdvertisements();
    this.coworkingType = this.listingSearchService.findCoworkingTypeFromSearch()
      ? this.listingSearchService.findCoworkingTypeFromSearch()
      : 'shared';
    this.coworkingPositions = this.listing.coworkingPositions;
    this.faMapMarkerAlt = faMapMarkerAlt;
    this.isLoggedIn$ = this.auth.$isLoginSubject;
    this.screenHeight = window.outerHeight;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.listingAdmin = this.checkRole('ROLE_LISTING_ADMIN');
    this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    this.listingUpdateSubscription = this.subscribeToListingUpdate();
    if (this.isBasic()) {
      if (this.listing.building) {
        this.getRentRolls();
      }
    }
    this.priceSharedOption = this.listing.coworkingDailyPrice > 0 || this.listing.coworkingMonthlyPrice > 0;
    this.pricePrivateOption =
      this.listing.coworkingPrivateDailyPrice > 0 || this.listing.coworkingPrivateMonthlyPrice > 0;
    if (!this.priceSharedOption || !this.pricePrivateOption) {
      this.coworkingType = this.priceSharedOption ? 'shared' : 'private';
      this.calculateCoworkingValues(this.coworkingType);
    }

    this.revisionChanges();
  }

  async revisionChanges() {
    this.revisionListings = await this.listingDetailService.revisionChanges(
      this.listing.baseId,
      this.listing,
      this.i18nService.getCurrentLanguage()
    );
    if (this.revisionListings.length > 0) {
      this.seeRevisionChanges = true;
      this.revisionListings.forEach((element: any) => {
        if (element.fieldName == 'COWORKING_CHANGES') {
          element.currentValue = '';
          element.newValue = this.i18nService.get('global.revisionChanges.COWORKING_CHANGES_NEW');
        }

        if (element.fieldName == 'PHOTOS_CHANGE') {
          element.currentValue = '';
          element.newValue = this.i18nService.get('global.revisionChanges.PHOTOS_CHANGE_NEW');
        }

        if (element.currentValue == 'true') {
          element.currentValue = this.i18nService.get('global.revisionChanges.yes');
        }

        if (element.newValue == 'true') {
          element.newValue = this.i18nService.get('global.revisionChanges.yes');
        }

        if (element.currentValue == 'false') {
          element.currentValue = this.i18nService.get('global.revisionChanges.no');
        }

        if (element.newValue == 'false') {
          element.newValue = this.i18nService.get('global.revisionChanges.no');
        }

        element.fieldName = `global.revisionChanges.${element.fieldName}`;
      });
    }
  }

  ngOnDestroy() {
    if (this.listingUpdateSubscription) {
      this.listingUpdateSubscription.unsubscribe();
    }
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.screenHeight = event.target.innerHeight;
  }

  async openEditListingMenu(event: any, data?: any) {
    event.stopPropagation();
    data = await this.listingDetailService.getRevisionOrActiveVersionBasedOnUserRole(data);
    this.dialogRef = this.dialog.open(MyListingEditComponent, {
      height: 'auto',
      width: '550px',
      data: data ? { data: data, myListingPage: false } : null
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  subscribeToListingUpdate() {
    return this._eventChecker.updateListing$.subscribe((listing: any) => {
      if (listing && this.listingsInSameProperty) {
        this.listingsInSameProperty = this.listingsInSameProperty.map((listingInSameProp: any) => {
          if (listing.id === listingInSameProp.id) {
            return listing;
          }
          return listingInSameProp;
        });
      }
    });
  }

  checkRole(role: string) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles ? user.roles.includes(role) : false;
    } catch (e) {
      console.log('roles undefined --> ', e);
    }
  }

  async getRentRolls() {
    this.availableSpaces = await this.buildingRentRollService.getAll(this.listing.building.id);
    if (this.availableSpaces) {
      this.availableSpaces = this.availableSpaces
        .filter(brr => {
          return brr.tenantName === 'Vago' || (brr.tenantNameOption && brr.tenantNameOption.id === 1);
        })
        .map(brr => {
          brr.suiteOrModule = this.suiteOrModule(brr);
          brr.floorOrWarehouse = this.floorOrWarehouse(brr);
          return brr;
        })
        .sort((brr1, brr2) => {
          if (this.listing.building.buildingType.id === 1001) {
            if (Number(brr1.warehouse) > Number(brr2.warehouse)) {
              return -1;
            }
            if (Number(brr1.warehouse) < Number(brr2.warehouse)) {
              return 1;
            }
            return 0;
          } else if (this.listing.building.buildingType.id === 2001) {
            if (Number(brr1.floor) > Number(brr2.floor)) {
              return -1;
            }
            if (Number(brr1.floor) < Number(brr2.floor)) {
              return 1;
            }
            return 0;
          }
        });
      if (this.availableSpaces.length > 0) {
        if (this.listing.building.buildingType.id === 1001) {
          this.availableSpaceCols[0] = 'global.listing-detail.basic.warehouse';
          this.availableSpaceCols[1] = 'global.listing-detail.basic.module';
          this.availableSpaceCols[2] = 'global.listing-detail.basic.privateArea';
        } else if (this.listing.building.buildingType.id === 2001) {
          this.availableSpaceCols[0] = 'global.listing-detail.basic.floor';
          this.availableSpaceCols[1] = 'global.listing-detail.basic.suite';
          this.availableSpaceCols[2] = 'global.listing-detail.basic.privateArea';
        }
      }
    }
  }

  getListingDetails() {
    this.listingDetailService
      .getListingDetails(this.listing.id)
      .toPromise()
      .then((result: any) => {
        this.listing = result.listing;
        this.listing.premiumAgents = result.premiumAgents;
      });
  }

  async getAdvertisements() {
    let amount = 6;
    if (this.listing.listingType.toLowerCase() === 'basic' || this.listing.listingType.toLowerCase() === 'promoted') {
      this.advertisements = await this.listingCarouselService.getAdvertisements(amount, this.listing.id).toPromise();
    }
  }

  getTypesTags(listing: any) {
    this.typesTags.push({ name: this.i18nService.getTranslation(this.listing.building.buildingType.name) });
    this.listingDetailService
      .getListingPropertySubtype(this.listing.id)
      .toPromise()
      .then((result: any) => {
        if (result) {
          result.forEach((element: any) => {
            this.typesTags.push({ name: this.i18nService.getTranslation(element.name) });
          });
        }
      });
  }

  getListingTags(listing: any) {
    this.listingTags = this.listingPropertyService.getTagTranslations(listing);
  }

  get form() {
    return this.angForm.controls;
  }

  async getListingBrokerImage(login: any) {
    let images = await this.listingDetailService.getProfilePicture(login).toPromise();
    return images;
  }

  getMediumImg(id: any) {
    return this.imgService.mediumThumbnail(id);
  }

  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      note: ['', Validators.required]
    });
  }

  navigateToBroker(id: any) {
    this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL);
    this.router.navigateByUrl(`/listing-user/${id}`, { state: { listing: this.listing } });
  }

  showContactEmail() {
    this.contactEmail = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
  }

  showContactPhone() {
    this.contactPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
  }

  showBrokerEmail(index: number) {
    this.brokerEmail[index] = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
  }

  showBrokerPhone(index: number) {
    this.brokerPhone[index] = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
  }

  showBrokerWhatsapp() {
    this.whatsappPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
  }

  registerUserActivity(userActivityType: UserActivityType) {
    if (this.listing.offeredByUser) {
      const userActivity: UserActivity = {
        module: userActivityType,
        brokerId: this.listing.offeredByUser.id,
        listingId: this.listing.id
      };
      this.userActivityService.saveUserActivity(userActivity);
    }
  }

  dismiss() {
    this.listingModalService.dismiss();
  }

  getListingsInSameProperty() {
    var listingSearchDto: any = { buildingId: this.listing.building.id, listingIdsToExclude: [this.listing.id] };
    this.listingService.searchListings(listingSearchDto, 1).then(listingsResponse => {
      this.totalCountListingsInSameProperty = parseInt(listingsResponse.headers.get('x-total-count'));
      this.showMoreIconListingsInSameProperty = this.totalCountListingsInSameProperty - this.currentPage * 20 > 0;
      this.listingsInSameProperty = listingsResponse.body.listings;
      this.listingIdsInSameProperty = listingsResponse.body.allListingIds;
    });
  }

  async getMoreListingsInSameProperty() {
    this.loading = true;
    var listingSearchDto: any = { buildingId: this.listing.building.id, listingIdsToExclude: [this.listing.id] };
    this.listingService.searchListings(listingSearchDto, ++this.currentPage).then(response => {
      let newListings: any = response.body.listings;
      this.showMoreIconListingsInSameProperty = this.totalCountListingsInSameProperty - this.currentPage * 20 > 0;
      if (newListings && newListings.length) {
        this.listingsInSameProperty = this.listingsInSameProperty.concat(newListings);
      }
      this.loading = false;
    });
  }

  async clickToChat() {
    const fullListingDetailsURL = encodeURI(this.buildUrl());
    const headerKey = 'global.listing-detail.clickToChat.whatsAppMsgHeader';
    const headerMsg = this.i18nService.get(headerKey);
    const msgToUserAgent = this.i18nService.get('global.listing-detail.clickToChat.msgToUserAgent');

    const message = `${headerMsg} ${fullListingDetailsURL}

${msgToUserAgent}`;
    this.whatsAppService.sendMessageViaWhataspp(this.listing.offeredByUser.whatsappNumber, message);

    return false;
  }

  private buildUrl() {
    return window.location.href.concat(this.i18nService.getLanguagePathURL());
  }

  async clickToEmail(ev: any) {
    const defaultMsg = this.i18nService.get('global.listing-detail.clickToEmail.msgToUserAgent');
    const message = `${defaultMsg}`;

    const popover = await this.agentNotPopOverCtrl.create({
      component: AgentNotificationComponent,
      event: ev,
      cssClass: 'popover-agent-email',
      componentProps: {
        defaultMsg: message,
        brokerId: this.listing.offeredByUser.id,
        listingId: this.listing.id,
        popupCtrl: this.agentNotPopOverCtrl,
        pageURLFrom: encodeURI(this.buildUrl())
      }
    });
    return await popover.present();
  }

  openEmail() {
    window.location.href = `mailto:${this.listing.offeredByUser.email}`;
    return false;
  }

  coworkingTypeChanged(event: CustomEvent) {
    this.coworkingType = event.detail.value;
    this.calculateCoworkingValues(event.detail.value);
  }

  coworkingPositionValueChanged(event: CustomEvent) {
    this.calculateCoworkingValues(this.coworkingType);
  }

  calculateCoworkingValues(coworkingType: string) {
    let pos = this.coworkingPositions && this.coworkingPositions > 0 ? this.coworkingPositions : 1;
    let found = false;
    let dailyPrice = 0;
    let monthlyPrice = 0;
    this.listing.coworkingEntries.forEach((item: any) => {
      if (item.positionIni <= pos) {
        // Calculate using this item
        dailyPrice = coworkingType != 'private' ? item.sharedDailyByPosition : item.nonSharedDailyByPosition;
        monthlyPrice = coworkingType != 'private' ? item.sharedMonthlyByPosition : item.nonSharedMonthlyByPosition;
      }
    });

    if (this.coworkingPositions > this.listing.coworkingMaxPositions) {
      this.coworkingDailyPrice = null;
      this.coworkingMonthlyPrice = null;
    } else {
      if (this.coworkingPositions > 0) {
        this.listing.coworkingPositions = this.coworkingPositions;
        if (coworkingType == 'private') {
          this.listing.sharedPrivatePositionsText =
            this.coworkingPositions == 1
              ? this.i18nService.get('global.privatePosition')
              : this.i18nService.get('global.privatePositions');
        } else if (coworkingType == 'shared') {
          this.listing.sharedPrivatePositionsText =
            this.coworkingPositions == 1
              ? this.i18nService.get('global.sharedPosition')
              : this.i18nService.get('global.sharedPositions');
        }
      }
      this.coworkingDailyPrice = dailyPrice ? pos * dailyPrice : null;
      this.coworkingMonthlyPrice = monthlyPrice ? pos * monthlyPrice : null;
      this.listing.coworkingDP = this.coworkingDailyPrice;
      this.listing.coworkingMP = this.coworkingMonthlyPrice;
    }
  }

  openListYourSpotDialogue() {
    this.isLoggedIn$.pipe(take(1)).subscribe((val: any) => {
      if (val) {
        this.openListingMenu();
      } else {
        this.loginFormDialog.openDialog('my-listing');
      }
    });
  }

  private openListingMenu() {
    this.dialogRef = this.dialog.open(MyListingMenuComponent, {
      height: 'auto',
      width: '550px'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  isBasic(): Boolean {
    return this.listing.listingType.toLowerCase() === 'basic';
  }

  floorOrWarehouse(buildingRentRoll: any) {
    if (this.listing.building.buildingType.id === 1001) {
      return buildingRentRoll.warehouse;
    } else if (this.listing.building.buildingType.id === 2001) {
      return buildingRentRoll.floor;
    }
  }

  suiteOrModule(buildingRentRoll: any) {
    if (this.listing.building.buildingType.id === 1001) {
      return buildingRentRoll.module;
    } else if (this.listing.building.buildingType.id === 2001) {
      return buildingRentRoll.suiteNumber;
    }
  }

  private setListing() {
    this.marker = { lat: this.listing.building.latitude, lng: this.listing.building.longitude };
  }

  private getNearestListings(state: any) {
    const pageNumber: number = 1;
    const searchDTO: any = this.nearbyListingSvc.getSearchCriteriaState(state, 'searchCriteria');
    const listingNearbySearchCriteria: any = this.nearbyListingSvc.setNearbyListingSearchCriteria(
      searchDTO,
      this.listing.building
    );

    this.nearbyListings = [];
    this.nearbySearchCriteria = { searchDTO: searchDTO, checkBoxValues: state ? state.checkBoxValues : null };
    this.searchTags = state ? state.searchTags : null;
    this.listingService.searchNearByListing(listingNearbySearchCriteria, pageNumber).then(result => {
      this.nearbyListings = <any[]>result.body;
    });
  }

  public getSelectPropertyListing(result: any) {
    this.selectedBuildingName = result.selectedBuilding;
    this.selectedPropertyListings = result.listings;
    if (result.listings.length) {
      const SCREEN_HEIGHT: number = 850;
      const elem: any = this.screenHeight > SCREEN_HEIGHT ? this.listingMapView.el : 'LISTING-CARD';
      if (elem === 'LISTING-CARD') {
        this.scrollToView.emit(elem);
      } else {
        this.nearbyListingSvc.scrollIntoViewElem(elem);
      }
    }
  }

  public refetchListing(searchFilter: any) {
    const pageNumber: number = 1;
    this.listingService.searchNearByListing(searchFilter.searchDTO, pageNumber).then(result => {
      this.nearbySearchCriteria = { searchDTO: searchFilter.searchDTO, checkBoxValues: searchFilter.checkBoxValues };
      this.selectedPropertyListings = [];
      this.nearbyListings = <any[]>result.body;
    });

    this.searchTags = searchFilter.searchTags;
    this.removedTagsFromNearBy.emit({ searchTags: this.searchTags, nearbySearchCriteria: this.nearbySearchCriteria });
  }

  public showPricingOptionsSection() {
    this.priceSharedOption = this.listing.coworkingDailyPrice > 0 || this.listing.coworkingMonthlyPrice > 0;
    this.pricePrivateOption =
      this.listing.coworkingPrivateDailyPrice > 0 || this.listing.coworkingPrivateMonthlyPrice > 0;
    //this.coworkingType = this.priceSharedOption ? 'shared' : 'private';
    return this.listing.coworkingMaxPositions && (this.priceSharedOption || this.pricePrivateOption);
  }
}
