import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/core/auth';
import { TurnOffNotification } from '@app/models/turn-off-notifications.model';
import { NotificationService } from '../services/notification.service';
import { NotificationRestrictionPayload } from '../../models/notifications/notification-restrictions-payload.model';

@Component({
  selector: 'app-settings-notification',
  templateUrl: './settings-notification.component.html',
  styleUrls: ['./settings-notification.component.scss']
})
export class SettingsNotificationComponent implements OnInit {
  isMobile: boolean = false;
  turnOff: TurnOffNotification = new TurnOffNotification();
  recommendation = true;
  favorites = true;
  spotSet = true;
  userRestrictions: NotificationRestrictionPayload = {
    favorites: { excludeEmail: false, excludeSpotApp: false, notificationActivityType: null },
    recommendation: { excludeEmail: true, excludeSpotApp: false, notificationActivityType: null },
    spotSet: { excludeEmail: false, excludeSpotApp: false, notificationActivityType: null }
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    private accountService: AccountService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  async ngOnInit() {
    const account: any = await this.accountService.getAccount().toPromise();
    this.favorites = account.favoriteNotification;
    this.recommendation = account.recommendationNotification;
    this.spotSet = account.spotSetNotification;
    this.userRestrictions = await this.notificationService.getNotificationRestrictions();
    console.log('User Resctrictions DB', this.userRestrictions);
  }

  turnOffNotification(notificationType: string) {
    this.turnOff.recommendationNotification = this.recommendation;
    this.turnOff.favoriteNotification = this.favorites;
    this.turnOff.spotSetNotification = this.spotSet;
    this.notificationService.turnOffNotification(this.turnOff).then(val => {});
    this.validateTooglesOnRestrictions(notificationType);
  }

  saveUserRestrictions(checkboxname: string, isChecked: boolean) {
    if (checkboxname == 'emailRecomCheck') {
      this.userRestrictions.recommendation.excludeEmail = !isChecked;
    }
    if (checkboxname == 'appRecomCheck') {
      this.userRestrictions.recommendation.excludeSpotApp = !isChecked;
    }

    if (checkboxname == 'emailSpotSetCheck') {
      this.userRestrictions.spotSet.excludeEmail = !isChecked;
    }
    if (checkboxname == 'appspotSetCheck') {
      this.userRestrictions.spotSet.excludeSpotApp = !isChecked;
    }

    if (checkboxname == 'emailFavCheck') {
      this.userRestrictions.favorites.excludeEmail = !isChecked;
    }
    if (checkboxname == 'appFavCheck') {
      this.userRestrictions.favorites.excludeSpotApp = !isChecked;
    }

    this.notificationService.saveNotificationRestrictions(this.userRestrictions);
  }

  validateTooglesOnRestrictions(notificationType: string) {
    let userRestrictionsCopy = { ...this.userRestrictions };

    if (notificationType == 'FAVORITES') {
      userRestrictionsCopy.favorites.excludeEmail = this.favorites;
      userRestrictionsCopy.favorites.excludeSpotApp = this.favorites;

      this.userRestrictions.favorites.excludeEmail = !this.favorites;
      this.userRestrictions.favorites.excludeSpotApp = !this.favorites;
    }

    if (notificationType == 'RECOMMENDATION') {
      userRestrictionsCopy.recommendation.excludeSpotApp = this.recommendation;
      userRestrictionsCopy.recommendation.excludeEmail = this.recommendation;

      this.userRestrictions.recommendation.excludeEmail = !this.recommendation;
      this.userRestrictions.recommendation.excludeSpotApp = !this.recommendation;
    }

    if (notificationType == 'SPOTSET') {
      userRestrictionsCopy.spotSet.excludeSpotApp = this.spotSet;
      userRestrictionsCopy.spotSet.excludeEmail = this.spotSet;

      this.userRestrictions.spotSet.excludeSpotApp = !this.spotSet;
      this.userRestrictions.spotSet.excludeEmail = !this.spotSet;
    }

    this.notificationService.saveNotificationRestrictions(userRestrictionsCopy);
  }
}
