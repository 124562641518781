import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { faChevronLeft, faChevronRight, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../services/company.service';
import { IonSlides } from '@ionic/angular';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { environment } from '@env/environment';
import { MetaService } from '@app/core/seo';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
var CompanyDetailsComponent = /** @class */ (function () {
    function CompanyDetailsComponent(activatedRoute, i18NService, breakpointObserver, companyService, routingHelperSrv, platformConfigHelper, imageService, popOverCtrlHelper, userActivityService, metaService) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.companyService = companyService;
        this.routingHelperSrv = routingHelperSrv;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.userActivityService = userActivityService;
        this.metaService = metaService;
        this.displayScrollToTop = false;
        this.agentsDTO = [];
        this.spotBuildingNames = [];
        this.totalDtos = [];
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.searching = false;
        this.continueReading = true;
        this.screenWidth = window.innerWidth;
        this.findTotalAgentCards();
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    CompanyDetailsComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
        this.findTotalAgentCards();
    };
    CompanyDetailsComponent.prototype.findTotalAgentCards = function () {
        this.visibleAgentCards = 3;
        if (this.screenWidth < 1024) {
            this.visibleAgentCards = 1;
        }
        else if (this.screenWidth < 1200) {
            this.visibleAgentCards = 2;
        }
        this.sliderPreviewOptions = {
            slidesPerView: this.visibleAgentCards,
            spaceBetween: 100,
            pagination: true
        };
        this.updateDots();
    };
    CompanyDetailsComponent.prototype.updateDots = function () {
        this.totalDtos = [];
        for (var agent in this.agentsDTO) {
            if (parseInt(agent) + this.visibleAgentCards > this.agentsDTO.length) {
                break;
            }
            this.totalDtos.push(agent);
        }
    };
    CompanyDetailsComponent.prototype.ngOnInit = function () {
        this.sharedIcon = faShareAlt;
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.getParamsUrl();
    };
    CompanyDetailsComponent.prototype.getParamsUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        try {
                            this.companyId = paramMap.companyId;
                            this.spotBuildingSearchRequest = { companyId: this.companyId };
                            this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];
                            this.companyService.getCompanyMetadataById(this.companyId).then(function (companyData) {
                                _this.metaService.setCompanyMetaTagsLight(companyData);
                                window.prerenderReady = true;
                            });
                            this.companyService.getCompanyById(this.companyId).then(function (company) {
                                _this.company = company;
                                _this.registerUserActivity(UserActivityType.PAGEVIEW_COMPANY, null, _this.company.id);
                                _this.companyName = company ? company.name : '';
                                _this.companyBio = _this.company ? _this.getDefaultTranslation(_this.company.biography) : '';
                                _this.companyService.getPhotosByCompanyId(_this.companyId).then(function (photos) {
                                    _this.companyPhotos = photos;
                                    _this.companyService.getAgentsWithSpotsFrom(_this.companyId).then(function (result) {
                                        _this.agentsDTO = result;
                                        _this.companyService.getSpotTitlesByCompanyId(_this.companyId).then(function (result) {
                                            _this.spotBuildingNames = result;
                                            //this.metaService.setCompanyMetaTags(this.company, this.agentsDTO, this.spotBuildingNames);
                                        });
                                        _this.updateDots();
                                    });
                                });
                            });
                        }
                        catch (error) {
                            console.error('Error : ', error);
                        }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    CompanyDetailsComponent.prototype.getLargeImg = function (id) {
        return this.imageService.largeThumbnail(id);
    };
    CompanyDetailsComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    CompanyDetailsComponent.prototype.getCroppedOrOriginalImage = function (photo) {
        if (photo) {
            if (photo.croppedImageId) {
                return photo.croppedImageId;
            }
            return photo.imageId;
        }
    };
    CompanyDetailsComponent.prototype.openGalleryImg = function (event) {
        var photos = [];
        if (this.companyPhotos) {
            photos = this.companyPhotos.map(function (blp) {
                var photo = {
                    id: blp.id,
                    croppedImageId: blp.croppedImageId,
                    imageId: blp.imageId,
                    orderId: blp.orderId,
                    originalImageId: blp.originalImageId
                };
                return photo;
            });
        }
        this.popOverCtrlHelper.openGalleryImageComponent(event, photos, this.company.name);
    };
    CompanyDetailsComponent.prototype.buildTitleMetatag = function (company) {
        var metaTagTitle = '';
        var companyTitleTag = company.name;
        metaTagTitle = companyTitleTag + " | SiiLA SPOT";
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var companyName = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(companyTitleTag);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag };
    };
    CompanyDetailsComponent.prototype.setCompanyMetaTags = function () {
        var detailsMetaTags = this.metaService.buildCompanyMetatag(this.company, this.agentsDTO, this.spotBuildingNames);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = this.company.biography
            ? this.removeHTMLCode(this.i18NService.getTranslation(this.company.biography))
            : '';
        var locale = this.i18NService.getCurrentLocale();
        var companyTitle = this.i18NService.getTranslation(this.company.name);
        var fullDetailsURL = window.location.href;
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: this.company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.company.mainImageId))
                    : null
            },
            {
                name: 'image',
                content: this.company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.company.mainImageId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: this.company.mainImageId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.company.mainImageId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.metaService.setDynamicTags(tags, fullTitleText);
    };
    CompanyDetailsComponent.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    CompanyDetailsComponent.prototype.getDefaultTranslation = function (translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.log(e);
        }
    };
    CompanyDetailsComponent.prototype.shareCompany = function (event) {
        var url = window.location.href;
        var sharedTitle = this.company.name;
        var modalTitle = this.i18NService.get('ShareCompanyProfile');
        var input = { url: url, sharedTitle: sharedTitle, modalTitle: modalTitle };
        this.userActivityService.trackCompanyShare(this.company.id);
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    };
    CompanyDetailsComponent.prototype.getNextPhoto = function ($event) {
        var _this = this;
        this.previewSlider.getActiveIndex().then(function (index) {
            if (index < _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
            }
            else {
                if ($event.type === 'click') {
                    _this.previewSlider.slideNext();
                    _this.updatePhotoIndex();
                }
                else {
                    _this.updatePhotoIndex();
                }
            }
        });
    };
    CompanyDetailsComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.previewSlider.getActiveIndex().then(function (index) {
            if (index != _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
                _this.syncSliders();
            }
        });
    };
    CompanyDetailsComponent.prototype.isStartOfSlide = function () {
        return this.currentPhotoIndex <= 0;
    };
    CompanyDetailsComponent.prototype.isEndOfSlide = function () {
        return this.currentPhotoIndex + this.visibleAgentCards >= this.agentsDTO.length;
    };
    CompanyDetailsComponent.prototype.arrowsNavigation = function (event, index) {
        var toIndex = this.currentPhotoIndex + index;
        this.slideToIndex(event, toIndex);
    };
    CompanyDetailsComponent.prototype.slideToIndex = function (event, index) {
        event.stopPropagation();
        this.currentPhotoIndex = index;
        this.syncSliders();
    };
    CompanyDetailsComponent.prototype.syncSliders = function () {
        this.previewSlider.slideTo(this.currentPhotoIndex);
    };
    CompanyDetailsComponent.prototype.isFooterVisible = function (event) {
        this.displayScrollToTop = event.visible;
    };
    CompanyDetailsComponent.prototype.navigateToBroker = function (brokerId) {
        this.registerUserActivity(UserActivityType.BROKER_SHOWDETAIL, brokerId, this.company.id);
        this.routingHelperSrv.navigateToBrokerDetailsPage(brokerId);
    };
    CompanyDetailsComponent.prototype.registerUserActivity = function (userActivityType, brokerId, companyId) {
        var userActivity = {
            module: userActivityType,
            brokerId: brokerId,
            companyId: companyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    return CompanyDetailsComponent;
}());
export { CompanyDetailsComponent };
