import { Injectable, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class PWAService implements OnInit {
  public deferredInstallPrompt: any;
  //made change to false
  public appInstalled$ = new BehaviorSubject<boolean>(true);
  private appInstalled: boolean = false;

  constructor(private swUpdate: SwUpdate, private cookies: CookieService) {}

  ngOnInit() {}

  getPwaInstallSignature() {
    return this.cookies.get('pwaInstalled') ? JSON.parse(this.cookies.get('pwaInstalled')) : null;
  }

  pwaSaveInstallSignature() {
    return this.cookies.set('pwaInstalled', JSON.stringify(new Date()));
  }

  signatureExpired() {
    const pwaInstalled = this.cookies.get('pwaInstalled');
    const months = 3;
    let CurrentDate = new Date();
    CurrentDate.setMonth(CurrentDate.getMonth() + months);
    if (pwaInstalled) {
      return new Date(JSON.parse(pwaInstalled)) > CurrentDate;
    } else {
      return false;
    }
  }

  pwaRemoveInstallSignature() {
    return this.cookies.delete('pwaInstalled');
  }

  promptPWAInstall() {
    if (this.deferredInstallPrompt) {
      this.deferredInstallPrompt.prompt().catch((err: any) => {
        console.log(err);
      });
    }
  }

  setDeferredPrompt(prompt: any) {
    this.deferredInstallPrompt = prompt;

    this.deferredInstallPrompt.userChoice.then((evt: any) => {
      if (evt) {
        console.log(evt);
      }
    });
  }

  isAppInstalled(): boolean {
    return this.appInstalled;
  }
}
