import { Injectable, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from './account.service';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';

import { AlertController } from '@ionic/angular';
import { ImageService } from '../image.service';
import { WebsocketService, SiilaBusAuthActions } from '@app/websockets';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { I18nService } from '../i18n.service';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';

@Injectable()
export class AuthenticationService {
  private host: string = 'host';
  private dev: string = '/oauth/token';
  private sessionPersistenceEndpoint: string = '/api/authenticate';

  public $isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  public userAccount: any;
  public homepageClient: any;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private account: AccountService,
    private registerService: RegisterService,
    private router: Router,
    private alertCtrl: AlertController,
    private imageservice: ImageService,
    private websocketService: WebsocketService,
    private myMessageService: MyMessageService,
    private socketService: SocketIOService,
    private i18nService: I18nService,
    private listingSearchService: ListingSearchService
  ) {
    this.loadUnreadMessages();
    window.addEventListener('storage', this.message_receive);
  }

  message_broadcast(message: string) {
    localStorage.setItem('message', message);
    localStorage.removeItem('message');
  }

  message_receive(ev: any) {
    if (ev.key == 'message') {
      var message = ev.newValue;
      if (message && (message == 'logout' || message == 'login')) {
        console.log('Received login/logout cross-tab call');
        window.location.href = environment.spotServerUrl;
      }
    }
  }

  loadUnreadMessages() {
    if (localStorage.getItem('unreadCount')) {
      this.myMessageService.setUnreadCount(parseInt(localStorage.getItem('unreadCount')));
    }
  }

  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  public hasToken(): boolean {
    return !!localStorage.getItem('token');
  }

  public getToken(): string {
    return localStorage.getItem('token') || '';
  }

  public isLoggedIn(): Observable<boolean> {
    return this.$isLoginSubject.asObservable();
  }

  public isSessionDestroyed(): Observable<any> {
    return this.http.get(this.sessionPersistenceEndpoint);
  }

  public submitLoginCredentials(username: any, password: any, keepUrlOnBadCredentials: boolean = false) {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('secret', 'mySecretOAuthSecret')
      .set('grant_type', 'password')
      //.set('scope', 'read write')
      .set('scope', 'spot')
      .set('client_id', 'siilaapp');

    return this.http
      .post(this.dev, body, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
      })
      .toPromise()
      .then(res => {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('token', JSON.stringify(res));
        }

        return;
      })
      .then(async () => {
        const account: any = await this.account.getAccount().toPromise();
        localStorage.setItem('socket-id', account.id);
        this.$isLoginSubject.next(true);
        const userPhotos: any = await this.account.getUserPhotoList().toPromise();
        const image = this.account.getUploadedImageId(userPhotos);
        const profileImage = this.imageservice.mediumThumbnail(image.profileImageId);

        localStorage.setItem('profileImage', profileImage);
        localStorage.setItem('unreadCount', this.myMessageService.getUnreadMessages() + '');
        localStorage.setItem('user', JSON.stringify(account));
        this.account.currentUser$.next(account);

        if (account && account.company && account.company.avoidPiwikTracking === true) {
          localStorage.setItem('userType', 'SiiLA');
        } else {
          localStorage.setItem('userType', 'Customer');
        }
        localStorage.setItem('userCompany', account.company.name);
        this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.MY_LISTINGS, account);
        this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.LISTING_ANALYTICS, account);
        sessionStorage.removeItem(SelectedFilterKey.SPOT_BUILDING_SEARCH);
        sessionStorage.removeItem(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);

        const tokenValue = JSON.parse(localStorage.getItem('token'));
        const user = account;
        this.userAccount = account;
        //this.websocketService.send(SiilaBusAuthActions.AUTHORIZE, { tokenValue, user });

        //this.socketService.updateUnreadMessageCount();
        //this.socketService.setCurrentUser();
        //this.socketService.connect();

        this.message_broadcast('login');
        // const userDevices = await this.deviceRegistration.retrieveUserDevices();
        return {
          account: account,
          // devices: userDevices,
          registrationRequired: account.deviceRegistrationRequired
        };
      })
      .catch(e => {
        const errorDescription = e.error && (e.error.error_description || 'error');
        switch (e.error.error_description) {
          case 'LOCKED_ACCOUNT':
            console.log('LOCKED');
            this.showMessage('Account Locked', 'Your account has been locked.');
            break;
          case 'INACTIVE_ACCOUNT':
            console.log('INACTIVE');
            this.showMessage('Inactive Account/Company', 'Either your company or your account has been deactivated.');
            break;
          case 'INACTIVE_SPOT_ACCOUNT':
            const inactiveAccount = this.i18nService.get('global.inactiveAccount');
            const accountConfirmation = this.i18nService.get('global.AccountConfirmation');
            this.showMessage(inactiveAccount, accountConfirmation, false, 'email-confirm-height');
            break;
          default:
            if (e.error.error_description.slice(0, 22) == 'SHOULD_CHANGE_PASSWORD') {
              this.registerService.setKeyForChangePassword(e.error.error_description.split(':')[1]);
              this.router.navigateByUrl('/change-password');
            } /* else {
              const badCredentialsMsg = this.i18nService.get('global.badCredentials');
              const badCredentialsDesc = this.i18nService.get('global.badCredentialsDesc');
              this.showMessage(badCredentialsMsg, badCredentialsDesc, keepUrlOnBadCredentials);
            } */
        }
        return {
          error: errorDescription
        };
      });
  }

  public refreshAccessToken(): Observable<Object> {
    const refreshToken = this.getToken() ? JSON.parse(this.getToken()).refresh_token : null;
    if (!refreshToken) {
      console.info(this.i18nService.get('TokenExpiration.noAccessTokenMsg'));
    }

    const body = new HttpParams()
      .set('secret', 'mySecretOAuthSecret')
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('scope', 'spot')
      .set('client_id', 'siilaapp');

    return this.http
      .post(this.dev, body, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
      })
      .pipe(
        tap((authData: {}) => {
          localStorage.setItem('token', JSON.stringify(authData));
        }),
        map((authData: { access_token: string }) => authData.access_token)
      );
  }

  async showMessage(header: string, message: string, keepUrl?: boolean, cssClass?: string) {
    const okText = this.i18nService.get('global.OK');
    const alert = await this.alertCtrl.create({
      cssClass,
      header: header,
      message: message,
      buttons: [okText]
    });
    alert.onDidDismiss().then(() => {
      if (!keepUrl) {
        this.router.navigateByUrl('/');
      }
    });
    await alert.present();
  }

  completeLoginProcess(account: any) {
    if (account && account.roles.includes('ROLE_LISTING')) {
      // this.dialog.close();
      // this.router.navigate(["listings"]);
      //this.broadcastChannel.postMessage({ payload: 'LOGIN' });
    } else {
      // this.windowRef.nativeWindow.location.href = this.windowRef.nativeWindow.location.hostname.includes(
      //   "localhost"
      // )
      //   ? `http://localhost:8080/#!/community`
      //   : `https://devapp.siila.com.br/#!/community`;
    }
  }

  async registerDevice(account: any) {
    // const createdUserDevice = await this.deviceRegistration.createUserDevice();
    // this._log.info("created user device -> ", createdUserDevice);
    //Store cookie logic for new device
    // this._device.openDialog(null, RegistrationSteps.Success);
    // const registrationSuccessDialog = this._device.dialogRef;
    // registrationSuccessDialog.afterClosed().subscribe(e => {
    //   this.completeLoginProcess(account);
    // });
  }

  async logout() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.http.post(`${environment.apiURL}/logout`, {}).toPromise();
      } catch (e) {
        console.error('Terminating db session failed.', e);
      }
      this.removeCredentials();
      this.message_broadcast('logout');
      //this.broadcastChannel.postMessage('logout'); /* send */
      this.websocketService.send(SiilaBusAuthActions.KICK, JSON.stringify({ action: 'kick', data: 'kick' }));
      this.socketService.disconnect();
      if (this.router.url !== '/' && this.router.url !== '/') {
        this.router.navigate(['/']);
      } else {
        location.reload();
      }
    }
  }

  public removeCredentials() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('ls.token');
    localStorage.removeItem(SelectedFilterKey.FAVORITES_SEARCH);
    this.account.accountLogout();
    this.$isLoginSubject.next(false);
  }
}

export enum RegistrationSteps {
  New = 'new',
  Existing = 'existing',
  Success = 'success'
}
