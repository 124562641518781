import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GoogleAnalyticsType } from './google-analytics-type';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private gaService: GoogleAnalyticsService) {}

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  public sentEvent(analyticsType: GoogleAnalyticsType, currentDevice: string) {
    let trackingId = null;
    let eventNameForGA = null;
    switch (analyticsType) {
      case GoogleAnalyticsType.CLICK_ON_EMAIL:
        eventNameForGA = 'Entrar em contato email';
        trackingId = environment.agentEmailTrackingID;
        break;
      case GoogleAnalyticsType.CLICK_ON_PHONE:
        eventNameForGA = 'Entrar em contato telefone';
        trackingId = environment.agentPhoneTrackingID;
        break;
      case GoogleAnalyticsType.CLICK_ON_WHATSAPP:
        eventNameForGA = 'Ver mais whatsapp';
        trackingId = environment.agentWhatsAppTrackingID;
        break;
      case GoogleAnalyticsType.CLICK_ON_GET_STARTED:
        eventNameForGA = 'Cadastre-se';
        trackingId = environment.getStartedUserRegistrationID;
        break;
      case GoogleAnalyticsType.CLICK_ON_INSTALL_APP:
        eventNameForGA = 'install';
        trackingId = environment.installAppTrackingID;
        break;
      case GoogleAnalyticsType.EMAIL_SENT:
        eventNameForGA = 'Enviar email';
        trackingId = environment.sendEmailTrackingID;
        break;
      default:
        console.error(' No tracking ');
        trackingId = null;
    }

    if (trackingId) {
      console.log('Sending GA to: ', trackingId, analyticsType);
      this.gaService.gtag('event', 'conversion', {
        send_to: trackingId,
        event_callback: this.callback()
      });
      this.gaService.event(`${eventNameForGA}`, 'Contact Events', currentDevice);
    }
  }
  private callback() {}

  public init() {
    if (environment.baseTrackingID && environment.baseTrackingID.length > 0) {
      this.configTrackingID(environment.baseTrackingID);
    }
    this.configTrackingID(environment.agentEmailTrackingID);
    this.configTrackingID(environment.agentPhoneTrackingID);
    this.configTrackingID(environment.agentWhatsAppTrackingID);
    this.configTrackingID(environment.getStartedUserRegistrationID);
  }

  public addUserIdToGA() {
    //(window as any).gtag('set', { 'user_id': userId });
    //this.gaService.event('User', 'Set User ID', userId);
    if (environment.analyticsTracking && environment.analyticsTracking.length > 0) {
      this.configTrackingID(environment.analyticsTracking);
    }
  }

  private configTrackingID(trackingId: string) {
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      var user = JSON.parse(localStorage.getItem('user'));

      if (!user || user == undefined) {
        script2.innerHTML =
          `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
          trackingId +
          `', {'send_page_view': true});
      `;
      } else {
        script2.innerHTML =
          `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('set', {'user_id': '` +
          user.id +
          `'});
        gtag('config', '` +
          trackingId +
          `', {'send_page_view': true, 'user_id': '` +
          user.id +
          `'});
        gtag('config', '` +
          trackingId +
          `', {'user_id': '` +
          user.id +
          `'});
      `;
        this.gaService.gtag('set', 'user_id', '' + user.id + '');
      }

      document.head.appendChild(script2);
      console.log(`${trackingId} configuration: OK`);
    } catch (ex) {
      console.error(`Error appending google analytics: ${trackingId}`);
      console.error(ex);
    }
  }
}
