import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SpotBuildingsSearchService } from '../../../spot-buildings/services/spot-buildings-search.service';
import { I18nService } from '../../../core/i18n.service';
import { SpotBuildingSearchRequest } from '../../../models/spot-buildings/spot-building-search.model';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
var HomeSearchControlComponent = /** @class */ (function () {
    function HomeSearchControlComponent(router, routinHelperSrv, i18nService, spotBuildingSearchService, _ts) {
        var _this = this;
        this.router = router;
        this.routinHelperSrv = routinHelperSrv;
        this.i18nService = i18nService;
        this.spotBuildingSearchService = spotBuildingSearchService;
        this._ts = _ts;
        this.spotBuildingTypes = {
            lease: 'L',
            sale: 'S',
            coworking: 'C',
            company: 'CPY'
        };
        this.propertyTypes = {
            industrial: 1001,
            office: 2001
        };
        this.faChevronRight = faChevronRight;
        this.advertisements = [];
        this.active = 1;
        this.searchModel = {
            propertyTypeId: this.propertyTypes.office,
            spotBuildingType: this.spotBuildingTypes.lease,
            keyword: null,
            companyId: null
        };
        this.allCompaniesWithSpots = [];
        this.companyCtrl = new FormControl();
        this.leaseLabel = _ts.instant('global.list-your-property.forLeaseHome');
        this.saleLabel = _ts.instant('global.list-your-property.forSaleHome');
        this.companyLabel = _ts.instant('global.list-your-property.forCompanyHome');
        this.selectCompany = _ts.instant('global.list-your-property.selectCompany');
        this.spotBuildingSearchService.findAllSpotCompanies().then(function (res) {
            _this.allCompaniesWithSpots = res;
            _this.companyCtrl.setValue('');
            _this.filteredCompanies = _this.companyCtrl.valueChanges.pipe(startWith(''), map(function (state) { return _this._filterStates(state); }));
        });
    }
    HomeSearchControlComponent.prototype.selectedState = function (event) {
        this.searchModel.companyId = null;
        var selectedCompanyName = event.option.value;
        var selectedCompany = this.allCompaniesWithSpots.filter(function (company) { return company.name == selectedCompanyName; });
        if (selectedCompany && selectedCompany.length > 0) {
            this.searchModel.companyId = selectedCompany[0].id;
            console.log(selectedCompany[0], this.searchModel);
        }
    };
    HomeSearchControlComponent.prototype._filterStates = function (value) {
        var _this = this;
        var filterValue = this._normalizeValue(value.toLowerCase());
        if (value == '') {
            return [];
        }
        var filteredOptions = this.allCompaniesWithSpots
            .filter(function (state) { return _this._normalizeValue(state.name.toLowerCase()).includes(filterValue); })
            .sort(function (a, b) { return a.name.localeCompare(b.name); });
        return filteredOptions;
    };
    HomeSearchControlComponent.prototype.highlightOption = function (option) {
        var filterValue = this._normalizeValue(this.companyCtrl.value.toLowerCase());
        var startIndex = this._normalizeValue(option.toLowerCase()).indexOf(filterValue);
        if (startIndex >= 0) {
            var endIndex = startIndex + filterValue.length;
            return (option.substring(0, startIndex) +
                '<span class="highlight-match" style="color: blue; font-weight: bold;">' +
                option.substring(startIndex, endIndex) +
                '</span>' +
                option.substring(endIndex));
        }
        return option;
    };
    HomeSearchControlComponent.prototype.getPropertyTypeKey = function (propertyTypeId) {
        switch (propertyTypeId) {
            case this.propertyTypes.industrial:
                return 'global.industrial';
            case this.propertyTypes.office:
                return 'global.office';
            default:
                return;
        }
    };
    HomeSearchControlComponent.prototype.portChange = function (event) {
        //console.log('Event: ', event);
    };
    HomeSearchControlComponent.prototype.isNotCompanySearch = function () {
        return this.searchModel.spotBuildingType != this.spotBuildingTypes.company;
    };
    HomeSearchControlComponent.prototype.isCompanySearch = function () {
        return this.searchModel.spotBuildingType == this.spotBuildingTypes.company;
    };
    HomeSearchControlComponent.prototype.setPropertyTypeId = function (type, listingType) {
        if (listingType) {
            this.setSpotBuildingType(listingType);
        }
        else {
            if (this.searchModel.spotBuildingType == this.spotBuildingTypes.coworking) {
                this.setSpotBuildingType(this.spotBuildingTypes.lease);
            }
        }
        this.searchModel = tslib_1.__assign({}, this.searchModel, { propertyTypeId: type, keyword: null, companyId: null });
    };
    HomeSearchControlComponent.prototype.setSpotBuildingType = function (tab) {
        this.searchModel = tslib_1.__assign({}, this.searchModel, { spotBuildingType: tab, keyword: null, companyId: null });
    };
    HomeSearchControlComponent.prototype.selectKeywordItem = function (item) {
        this.searchModel.keyword = item;
    };
    HomeSearchControlComponent.prototype.selectCompanySpot = function (id) {
        this.searchModel = tslib_1.__assign({}, this.searchModel, { companyId: id, keyword: null });
    };
    HomeSearchControlComponent.prototype.disableSearchBtn = function () {
        var disabled = false;
        if (this.isCompanyTabActive()) {
            if (!this.searchModel.companyId) {
                disabled = true;
            }
        }
        return disabled;
    };
    HomeSearchControlComponent.prototype.isCompanyTabActive = function () {
        return this.searchModel.spotBuildingType === this.spotBuildingTypes.company;
    };
    HomeSearchControlComponent.prototype.onSearch = function (advanced) {
        if (advanced === void 0) { advanced = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryParams, filters, req, isStateType, isCityType, isMarketType, response, lang, newUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isCompanyTabActive()) {
                            if (!this.searchModel.companyId) {
                                return [2 /*return*/];
                            }
                            this.routinHelperSrv.navigateToCompanyDetailsPage(this.searchModel.companyId);
                            return [2 /*return*/];
                        }
                        queryParams = {
                            propertyType: this.searchModel.propertyTypeId,
                            listingType: this.searchModel.spotBuildingType,
                            keyword: this.searchModel.keyword,
                            advanced: advanced
                        };
                        filters = JSON.stringify(queryParams);
                        if (filters) {
                            this.spotBuildingSearchService.saveFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS, filters);
                        }
                        req = new SpotBuildingSearchRequest();
                        req.listingLeaseOrSale = tslib_1.__spread(this.searchModel.spotBuildingType);
                        req.buildingTypes = ["" + this.searchModel.propertyTypeId];
                        req.keyword =
                            this.searchModel.keyword && this.searchModel.keyword.type == 'neighborhood'
                                ? this.searchModel.keyword.name
                                : null;
                        req.stateId = this.searchModel.keyword && this.searchModel.keyword.id ? this.searchModel.keyword.id : null;
                        req.stateId = null;
                        req.cityId = null;
                        req.marketId = null;
                        if (this.searchModel.keyword && this.searchModel.keyword.id) {
                            isStateType = this.searchModel.keyword.type == 'state';
                            isCityType = this.searchModel.keyword.type == 'city';
                            isMarketType = this.searchModel.keyword.type == 'market';
                            if (isStateType) {
                                req.stateId = this.searchModel.keyword.id;
                            }
                            if (isCityType) {
                                req.stateId = this.searchModel.keyword.stateId;
                                req.cityId = this.searchModel.keyword.id;
                            }
                            if (isMarketType) {
                                req.marketId = this.searchModel.keyword.id;
                            }
                        }
                        return [4 /*yield*/, this.spotBuildingSearchService.getUrlFromListingsSearchDto(req)];
                    case 1:
                        response = _a.sent();
                        lang = this.i18nService.getCurrentLanguage();
                        response.url = JSON.parse(response.url);
                        newUrl = "" + response.url[lang] + (response.keyword ? '?keyword=' + response.keyword : '');
                        if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                            this.router.navigateByUrl(this.i18nService.addCurrentLanguageToPath("" + this.routinHelperSrv.getSpotSearchURL() + newUrl));
                        }
                        else {
                            this.router.navigateByUrl("" + this.routinHelperSrv.getSpotSearchURL() + newUrl);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HomeSearchControlComponent.prototype._normalizeValue = function (value) {
        var accentsMap = {
            á: 'a',
            é: 'e',
            í: 'i',
            ó: 'o',
            ú: 'u',
            à: 'a',
            è: 'e',
            ì: 'i',
            ò: 'o',
            ù: 'u',
            ä: 'a',
            ë: 'e',
            ï: 'i',
            ö: 'o',
            ü: 'u',
            â: 'a',
            ê: 'e',
            î: 'i',
            ô: 'o',
            û: 'u',
            ã: 'a',
            õ: 'o',
            ñ: 'n',
            ç: 'c',
            ā: 'a',
            ē: 'e',
            ī: 'i',
            ō: 'o',
            ū: 'u',
            ă: 'a',
            ĕ: 'e',
            ĭ: 'i',
            ŏ: 'o',
            ŭ: 'u',
            ą: 'a',
            ę: 'e',
            į: 'i',
            ų: 'u',
            å: 'a',
            ø: 'o',
            ș: 's',
            ț: 't',
            Ă: 'A',
            Ș: 'S',
            Ț: 'T'
        };
        return value.replace(/./g, function (c) { return accentsMap[c] || c; });
    };
    return HomeSearchControlComponent;
}());
export { HomeSearchControlComponent };
