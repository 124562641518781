import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginFormComponent } from './login-form.component';

@Injectable()
export class LoginFormDialogService {
  private dialogRef: any;

  constructor(private dialog: MatDialog) {}

  openDialog(data?: any) {
    this.dialogRef = this.dialog.open(LoginFormComponent, {
      width: '315px',
      data: data ? data : null,
      autoFocus: true,
      restoreFocus: true
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      console.log('Contact dismissed', result);
    });

    return this.dialogRef;
  }
}
